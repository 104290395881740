/* eslint-disable no-param-reassign */
import React from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Formik } from 'formik';
import { DefaultSelect } from 'components/Form';
import './FilteredTabs.scss';

export default class FilteredTabs extends React.Component {
  constructor(props) {
    super(props);
    const { initialTab } = this.props;
    this.state = {
      activeTab: initialTab,
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle = tab => {
    const { activeTab } = this.state;
    if (activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };

  render() {
    const { border, className, content, alignment, size } = this.props;
    const { activeTab } = this.state;
    const allTabs = content;
    const selectOptions = allTabs.map(tab => ({
      label: `${tab.tabNameReadable || tab.tabName} (${tab.count})`,
      value: tab.tabName,
      onChange: tab.onChange,
    }));
    const defaultSelectOption = selectOptions.find(
      option => option.value === activeTab
    );

    return (
      <>
        <div className="filtered-tabs--mobile">
          <Formik>
            <DefaultSelect
              name="statusSelector"
              options={selectOptions}
              defaultValue={defaultSelectOption}
              hideValidationStyling
              onChange={e => {
                if (e !== '') {
                  this.toggle(e);
                  selectOptions.find(option => option.value === e).onChange();
                }
              }}
            />
          </Formik>
        </div>
        <div
          className={classnames(
            'filtered-tabs filtered-tabs--desktop',
            className,
            { 'bordered-bottom': border === 'bottom' },
            { 'bordered-top': border === 'top' },
            { 'filtered-tabs-sm': size === 'sm' },
            { 'filtered-tabs-lg': size === 'lg' }
          )}
        >
          <div className={classnames('tabs-wrap', alignment)}>
            <Nav tabs className={classnames({})}>
              {content.map((item, index) => (
                <NavItem key={`${1 + index}.link`}>
                  {item.tabName && (
                    <NavLink
                      className={classnames({
                        active: activeTab === item.tabName,
                      })}
                      onClick={e => {
                        if (item.tabName !== '') {
                          item.onChange();
                          this.toggle(item.tabName);
                          e.stopPropagation();
                        }
                      }}
                      tag="button"
                      type="button"
                    >
                      {item.tabNameReadable || item.tabName}
                    </NavLink>
                  )}
                </NavItem>
              ))}
            </Nav>
          </div>
        </div>
      </>
    );
  }
}

FilteredTabs.propTypes = {
  border: PropTypes.oneOf(['default', 'top', 'bottom']),
  className: PropTypes.string,
  initialTab: PropTypes.string.isRequired,
  content: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        tabName: PropTypes.string,
        callbackFunc: PropTypes.func,
      })
    )
  ).isRequired,
  alignment: PropTypes.string,
  size: PropTypes.oneOf([null, 'sm', 'lg']),
};

FilteredTabs.defaultProps = {
  border: 'default',
  className: '',
  alignment: '',
  size: null,
};

/* eslint-enable no-param-reassign */
