import React from 'react';
import PropTypes from 'prop-types';
import { compose, withState, withHandlers, withProps } from 'recompose';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import { visibility } from 'shared/enhancers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretUp, faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import { get } from 'lodash';
import { UIMessage } from 'components';
import Artwork from 'img/blank-states/search.png';

import TableToolbar from './TableToolbar';
import DataTablePagination from './Pagination';
import TableActions from './TableActions';
import { changeSort, changePage, changeRowsPerPage } from './actions';
import filterActions from './filterActions';

import './DataTable.scss';

const selectTableMetaData = (state, { name }) => state.dataTable[name] || {};
const enhance = compose(
  withProps(({ visible }) => ({
    visible: ((visible === undefined || visible === null) && true) || visible,
  })),
  visibility,
  connect(
    (state, props) => {
      const metaData = selectTableMetaData(state, props);

      return {
        metaData,
        formatExtraData: {
          ...props.formatExtraData,
        },
      };
    },
    (dispatch, props) => ({
      onSortedChange: sorted =>
        dispatch(
          changeSort({
            name: props.name,
            sorted,
          })
        ),
      onPageChange: page =>
        dispatch(
          changePage({
            name: props.name,
            page,
          })
        ),
      onPageSizeChange: pageSize =>
        dispatch(
          changeRowsPerPage({
            name: props.name,
            pageSize,
          })
        ),
    })
  ),
  withState('searchText', 'onSearchChanged'),
  withState('storyStatusFilters', 'updateStoryStatusFilters', () =>
    localStorage.getItem('storyStatusFilters')
      ? localStorage.getItem('storyStatusFilters')
      : ''
  ),
  withState('adStatusFilters', 'updateAdStatusFilters', () =>
    localStorage.getItem('adStatusFilters')
      ? localStorage.getItem('adStatusFilters')
      : ''
  ),
  withState('selected', 'setSelected'),
  withHandlers({
    isSelected: props => id => props.selected.indexOf(id) > -1,
    onSearchChanged: props => searchText => {
      props.onSearchChanged(searchText);
      props.onPageChange(0);
    },
    updateStoryStatusFilters: props => storyStatusFilters => {
      localStorage.setItem('storyStatusFilters', storyStatusFilters);
      props.updateStoryStatusFilters(storyStatusFilters);
    },
    updateAdStatusFilters: props => adStatusFilters => {
      localStorage.setItem('adStatusFilters', adStatusFilters);
      props.updateAdStatusFilters(adStatusFilters);
    },
  })
);

function getCurrencyValue(currencySetting, value) {
  if (currencySetting !== undefined) {
    return new Intl.NumberFormat(
      currencySetting.locale !== undefined ? currencySetting.locale : 'en-US',
      {
        style: 'currency',
        currency:
          currencySetting.currencyCode !== undefined
            ? currencySetting.currencyCode
            : 'USD',
        minimumFractionDigits:
          currencySetting.minimumFractionDigits !== undefined
            ? currencySetting.minimumFractionDigits
            : 2,
        maximumFractionDigits:
          currencySetting.maximumFractionDigits !== undefined
            ? currencySetting.maximumFractionDigits
            : 2,
      }
    ).format(value !== undefined ? value : 0);
  }

  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(value !== undefined ? value : 0);
}

function transformCols(cols, actions) {
  const processedCols = cols.map(c => {
    const col = {
      ...c,
      Header: (
        <span className="column-name">
          {c.title} <FontAwesomeIcon icon={faCaretUp} className="sort-icon" />
          <FontAwesomeIcon icon={faCaretDown} className="sort-icon" />
        </span>
      ),
      accessor: c.field,
    };

    if (col.render) {
      col.Cell = ({ original, ...rest }) => col.render(original, rest);
    }

    if (c.type && !col.Cell) {
      if (c.type === 'date' || c.type === 'time' || c.type === 'datetime') {
        col.Cell = ({ value }) => {
          if (value instanceof Date) {
            return value.toLocaleDateString();
          }
          return value;
        };
      }

      if (c.type === 'currency') {
        col.Cell = ({ value }) => getCurrencyValue(c.currencySetting, value);
      }

      if (c.type === 'boolean') {
        col.Cell = ({ value }) => (value || false).toString();
      }
    }

    if (!col.Cell) {
      // To support empty values
      col.Cell = ({ value }) => value || '';
    }

    if (col.customSort) {
      col.sortMethod = col.customSort;
    }

    return col;
  });

  const processActionsCols = rowActions => {
    if (!rowActions.length) return [];

    return [
      {
        sortable: false,
        width: 151,
        className: 'table-actions',
        // eslint-disable-next-line react/prop-types
        Cell: ({ original }) => (
          <TableActions actions={rowActions} data={original} />
        ),
        Header: <span className="column-name">Actions</span>,
        headerClassName: 'table-actions',
      },
    ];
  };

  const additionalCols = processActionsCols(
    actions.filter(
      filterActions({
        selection: false,
      })
    )
  );

  return [...processedCols, ...additionalCols];
}

function transformDefaultSorted(defaultSorted = [], cols) {
  return [
    ...defaultSorted,
    ...cols
      .filter(x => x.defaultSort)
      .map(x => ({
        id: x.id || x.accessor,
        desc: x.defaultSort !== 'asc',
      })),
  ];
}

function processUpdateStoryStatusFilters(storyStatusFilters) {
  return data => {
    if (storyStatusFilters) {
      const results = data.filter(x => {
        if (x.status) {
          return storyStatusFilters.includes(x.status);
        }
        return true;
      });
      return results;
    }
    return data;
  };
}

function processUpdateAdStatusFilters(adStatusFilters) {
  return data => {
    if (adStatusFilters) {
      const results = data.filter(x => {
        if (x.status) {
          return adStatusFilters.includes(x.status);
        }
        return true;
      });
      return results;
    }
    return data;
  };
}

function processSearch(searchText = '', cols) {
  return data => {
    if (!searchText.length) return data;

    const dataCols = cols.map(x => x.filterable && x.accessor);

    const results = data.filter(x => {
      const r = dataCols.map(c =>
        JSON.stringify(get(x, c) || '')
          .toLowerCase()
          .includes(searchText.toLowerCase())
      );

      return r.filter(c => c).length > 0;
    });

    return results;
  };
}

function dataProcessing(processingOptions) {
  const {
    searchText,
    cols,
    storyStatusFilters,
    adStatusFilters,
    tableName,
  } = processingOptions;

  if (tableName === 'story' && storyStatusFilters !== null) {
    return data => {
      // eslint-disable-next-line max-len
      const afterUpdateStoryStatusProcessingResult = processUpdateStoryStatusFilters(
        storyStatusFilters
      )(data);
      const afterSearchProcessingResult = processSearch(
        searchText,
        cols
      )(afterUpdateStoryStatusProcessingResult);
      return afterSearchProcessingResult;
    };
  }
  if (tableName === 'interstitialAd' && adStatusFilters !== null) {
    return data => {
      const afterUpdateAdStatusProcessingResult = processUpdateAdStatusFilters(
        adStatusFilters
      )(data);
      const afterSearchProcessingResult = processSearch(
        searchText,
        cols
      )(afterUpdateAdStatusProcessingResult);
      return afterSearchProcessingResult;
    };
  }
  return data => {
    const afterSearchProcessingResult = processSearch(searchText, cols)(data);
    return afterSearchProcessingResult;
  };
}

// TODO: materal-table currently makes changes to the data that makes it back to
// redux.  This stops that.  Temporary fix until I get time to fix correct
const prepareData = data =>
  data.map(x => ({
    ...x,
  }));

const DataTable = ({
  actions,
  columns,
  data,
  defaultSorted,
  metaData,
  onSearchChanged,
  updateStoryStatusFilters,
  updateAdStatusFilters,
  options,
  searchText,
  storyStatusFilters,
  adStatusFilters,
  showStoryStatusFilters,
  showAdStatusFilters,
  tableName,
  getWeekList,
  ...props
}) => {
  const finalOptions = {
    emptyRowsWhenPaging: false,
    pageSize: 20,
    pageSizeOptions: [5, 10, 20, 50, 100],
    ...options,
    ...metaData,
  };

  const cols = transformCols(columns, actions);

  return (
    <ReactTable
      {...props}
      {...finalOptions}
      columns={cols}
      data={prepareData(data)}
      defaultSorted={transformDefaultSorted(defaultSorted, cols)}
      minRows={0}
      PaginationComponent={DataTablePagination}
      FilterComponent={<></>}
      resizable={false}
      resolveData={dataProcessing({
        searchText,
        storyStatusFilters,
        adStatusFilters,
        cols,
        tableName,
      })}
      noDataText={
        <UIMessage
          className="no-results"
          image={Artwork}
          title="No results found."
          message="Please check your search term for spelling and typos, or try removing filters."
        />
      }
    >
      {(state, makeTable) => (
        <React.Fragment>
          <TableToolbar
            tableName={tableName}
            onSearchChanged={onSearchChanged}
            searchText={searchText}
            updateAdStatusFilters={updateAdStatusFilters}
            updateStoryStatusFilters={updateStoryStatusFilters}
            storyStatusFilters={storyStatusFilters}
            adStatusFilters={adStatusFilters}
            showStoryStatusFilters={showStoryStatusFilters}
            showAdStatusFilters={showAdStatusFilters}
            actions={actions}
            state={state}
            options={options}
            getWeekList={getWeekList}
          />
          {makeTable()}
        </React.Fragment>
      )}
    </ReactTable>
  );
};

DataTable.propTypes = {
  title: PropTypes.string,
  columns: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  options: PropTypes.shape(),
  actions: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.shape(), PropTypes.func])
  ),
  data: PropTypes.arrayOf(PropTypes.shape()),
  storyStatusFilters: PropTypes.string,
  showStoryStatusFilters: PropTypes.bool,
  adStatusFilters: PropTypes.string,
  showAdStatusFilters: PropTypes.bool,
};

DataTable.defaultProps = {
  title: '',
  options: {},
  actions: [],
  data: [],
  storyStatusFilters: null,
  showStoryStatusFilters: false,
  adStatusFilters: null,
  showAdStatusFilters: false,
};

export default enhance(DataTable);
