// import { normalize } from 'normalizr';
import { has } from 'lodash';
import { isRSAA, RSAA } from 'redux-api-middleware';

export const requiresNormalizing = action => has(action, 'meta.schema');

export default store => next => action => {
  const state = store.getState();

  if (!isRSAA(action) || !state.account) {
    return next(action);
  }

  console.log('mike');
  console.log(state);
  return next({
    ...action,
    [RSAA]: {
      ...action[RSAA],
      headers: {
        ...action[RSAA].headers,
        Authorization: `Bearer ${state.account.access_token}`,
      },
    },
  });
};
