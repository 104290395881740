import React from 'react';
import './AssetToolbar.scss';
import { ButtonGroup, Col, Label, Input, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faSearch } from '@fortawesome/pro-regular-svg-icons';
import PropTypes from 'prop-types';
import Button from 'components/Button';

/** Asset browser toolbar (search, sort, filter, add) */

const AssetToolbar = props => {
  const {
    showSearch,
    showSort,
    showUpload,
    showFilter,
    toolbarFilters,
  } = props;
  return (
    <Row className="asset-toolbar align-items-center">
      {showSearch && (
        <Col md={6} lg={4} className="toolbar-item">
          <form className="form">
            <div className="form__form-group-field">
              <Label className="sr-only" htmlFor="assetSearch">
                Search
              </Label>
              <Input
                className="search-input"
                id="assetSearch"
                name="assetSearch"
                placeholder="eg. Folder or File Name"
                type="search"
              />
              <Button
                aria-label="Search Assets"
                type="button"
                className="input-group-append"
                color="secondary"
                icon={<FontAwesomeIcon icon={faSearch} />}
              />
            </div>
          </form>
        </Col>
      )}
      <Col xs={12} className="col-xl">
        <Row className="align-items-center justify-content-end">
          {showSort && (
            <Col xs={12} md="auto" className="toolbar-item">
              <Row className="align-items-center">
                <Col xs="auto">
                  <Label htmlFor="sortBy">Sort By:</Label>
                </Col>
                <Col>
                  <select
                    className="form-control"
                    id="sortBy"
                    name="sortBy"
                    openmenuonfocus="true"
                  >
                    <option value="1">Date Added</option>
                    <option value="2">Date Modified</option>
                    <option value="3">Name</option>
                  </select>
                </Col>
              </Row>
            </Col>
          )}
          {showFilter && toolbarFilters && (
            <Col xs={12} md="auto" className="toolbar-item">
              <ButtonGroup className="justified">
                {toolbarFilters.map((toolbarAction, i) => (
                  <Button
                    type="button"
                    color="primary"
                    size="sm"
                    onClick={toolbarAction.onClick}
                    outline={toolbarAction.outline}
                    // eslint-disable-next-line react/no-array-index-key
                    key={i}
                  >
                    {toolbarAction.title}
                  </Button>
                ))}
              </ButtonGroup>
            </Col>
          )}
          {showUpload && (
            <Col xs="auto" className="toolbar-item">
              <Button
                type="button"
                icon={<FontAwesomeIcon icon={faPlus} />}
                color="primary"
              >
                New
              </Button>
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  );
};

AssetToolbar.propTypes = {
  showSearch: PropTypes.bool,
  showSort: PropTypes.bool,
  showUpload: PropTypes.bool,
  showFilter: PropTypes.bool,
  toolbarFilters: PropTypes.arrayOf(PropTypes.shape({})),
};

AssetToolbar.defaultProps = {
  showSearch: true,
  showSort: true,
  showUpload: true,
  showFilter: true,
  toolbarFilters: [],
};

export default AssetToolbar;
