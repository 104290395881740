import { combineReducers } from 'redux';
import { sidebarReducer as sidebar } from 'components/Sidebar';
import { themeReducer as theme } from './theme';

const serviceWorker = (state = {}, action) => {
  const reducer = {
    SW_UPDATE_AVAILABLE: () => ({
      update: true,
    }),
    SW_UPDATED: () => ({
      updated: true,
    }),
  };

  if (!Reflect.has(reducer, action.type)) {
    return state;
  }

  return reducer[action.type](state, action);
};

export default combineReducers({
  theme,
  sidebar,
  serviceWorker,
});
