import { composeValidators, isRequired, matchesPattern } from 'revalidate';

export const processValidate = (name, validate = [], { required, pattern }) => {
  if (!validate.length && !required && !pattern) {
    return null;
  }

  let validators = validate.splice(0);

  if (required) {
    validators = [...validators, isRequired];
  }

  if (pattern) {
    validators = [...validators, matchesPattern(new RegExp(pattern))];
  }

  return composeValidators(...validators)(name);
};

export const getValidity = (touched, error) => ({
  valid: !!(touched && !error),
  invalid: !!(touched && error),
});
