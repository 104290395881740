import React from 'react';
import PropTypes from 'prop-types';
import { PieChart } from 'components/Charts';
import variables from 'scss/1-settings/colors.scss';
import './ProgressArc.scss';

/** Datavis component to show progress in an arc */

const colorOptions = {
  primary: variables.primary,
  secondary: variables.secondary,
  success: variables.success,
  info: variables.info,
  warning: variables.warning,
  danger: variables.danger,
  light: variables.light,
  dark: variables.dark,
};

function ProgressArc(props) {
  const { color, min, max, value, suffix } = props;
  const data = [
    { name: 'complete', value: value - min, fill: colorOptions[color] },
    {
      name: 'incomplete',
      value: max - value,
      fill: 'rgba(125, 125, 125, 0.15)',
    },
  ];
  return (
    <div className="progress-arc">
      <div className="arc-container">
        <PieChart
          aspect={2}
          cx="50%"
          cy="100%"
          data={data}
          endAngle={0}
          innerRadius="150%"
          label={false}
          showLegend={false}
          outerRadius="200%"
          startAngle={180}
          tooltip={false}
        />
        <span className="headline-figure">{value}</span>
      </div>
      <div className="range">
        <div className="min">
          <div className="value">{min}</div>
          <div className="units">{suffix}</div>
        </div>
        <div className="max">
          <div className="value">{max}</div>
          <div className="units">{suffix}</div>
        </div>
      </div>
    </div>
  );
}

ProgressArc.propTypes = {
  /** Color of the arc */
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'success',
    'info',
    'warning',
    'danger',
    'light',
    'dark',
  ]),
  /** Bottom of the range */
  min: PropTypes.number,
  /** Top of the range */
  max: PropTypes.number,
  /** Progress Value */
  value: PropTypes.number.isRequired,
  /** Units */
  suffix: PropTypes.string,
};

ProgressArc.defaultProps = {
  color: 'success',
  min: 0,
  max: 100,
  suffix: '',
};

export default ProgressArc;
