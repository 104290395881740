import { createStore, compose, applyMiddleware } from 'redux';
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import { apiMiddleware } from 'redux-api-middleware';
import { normalizeApiResponseMiddleware } from 'infrastructure/domain';
import apiAuthMiddleware from 'infrastructure/security/apiAuthMiddleware';
import createReducer from './reducers';

const constructStore = (initialState = {}, middlewares, sagaMiddleware) => ({
  ...createStore(createReducer(), initialState, middlewares),
  runSaga: sagaMiddleware.run,
  injectedReducers: {},
  injectedSagas: {},
});

export function configureStoreProd(initialState = {}) {
  const sagaMiddleware = createSagaMiddleware();
  const middlewares = [
    apiAuthMiddleware,
    apiMiddleware,
    normalizeApiResponseMiddleware,
    thunk,
    sagaMiddleware,
  ];
  return constructStore(
    initialState,
    compose(applyMiddleware(...middlewares)),
    sagaMiddleware
  );
}

export function configureStoreDev(initialState = {}) {
  const sagaMiddleware = createSagaMiddleware();
  const middlewares = [
    reduxImmutableStateInvariant(),
    apiAuthMiddleware,
    apiMiddleware,
    thunk,
    normalizeApiResponseMiddleware,
    sagaMiddleware,
  ];
  const composeEnhancers =
    // eslint-disable-next-line no-underscore-dangle
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const store = constructStore(
    initialState,
    composeEnhancers(applyMiddleware(...middlewares)),
    sagaMiddleware
  );

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('./reducers', () => {
      const nextReducer = createReducer(store.injectedReducers);

      store.replaceReducer(nextReducer);
    });
  }

  return store;
}
