import { compose, withProps, setDisplayName, wrapDisplayName } from 'recompose';
import apiManager from './apiManager';

export default Component =>
  compose(
    setDisplayName(wrapDisplayName(Component, 'withApi')),
    withProps(() => ({
      api: apiManager.getApi(),
    }))
  )(Component);
