import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Collapse } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/pro-regular-svg-icons';
import './Fieldset.scss';

/** Used like a normal HTML fieldset element, to define discrete sections in a form. */

const Fieldset = props => {
  const { children, className, collapsible, legend, closed } = props;
  const shouldShowButton = collapsible || (!collapsible && closed);
  const statusClassName = closed ? 'closed' : 'opened';
  const [collapse, setCollapse] = useState(!closed);
  const [statusClass, setStatusClass] = useState(statusClassName);

  const onEntering = () => {
    setStatusClass('opening');
  };

  const onEntered = () => {
    setStatusClass('opened');
  };

  const onExiting = () => {
    setStatusClass('closing');
  };

  const onExited = () => {
    setStatusClass('closed');
  };

  const toggle = () => {
    setCollapse(!collapse);
  };

  return (
    <fieldset
      className={classnames(
        'fieldset',
        'collapse__wrapper',
        statusClass,
        className,
        {
          collapsible,
        }
      )}
    >
      <legend>
        {legend}
        {shouldShowButton && (
          <button
            onClick={toggle}
            className="btn-light icon rounded fieldset-right-icon"
            type="button"
          >
            <FontAwesomeIcon icon={faChevronUp} />
          </button>
        )}
      </legend>
      <Collapse
        className="fieldset-content"
        isOpen={collapse}
        onEntering={onEntering}
        onEntered={onEntered}
        onExiting={onExiting}
        onExited={onExited}
      >
        {children}
      </Collapse>
    </fieldset>
  );
};

Fieldset.propTypes = {
  /** Contents of the fieldset */
  children: PropTypes.node.isRequired,
  /** Custom style class */
  className: PropTypes.string,
  /** Default to closed state if using collapsible */
  closed: PropTypes.bool,
  /** Can be hidden */
  collapsible: PropTypes.bool,
  /** Name of the fieldset */
  legend: PropTypes.string,
};

Fieldset.defaultProps = {
  className: '',
  closed: false,
  collapsible: false,
  legend: '',
};

export default Fieldset;
