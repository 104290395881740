import {
  faPlus,
  // faCopy,
  faTrashAlt,
  faPencil,
  faExternalLink,
  // faArrowAltRight,
  faChartLine,
} from '@fortawesome/pro-regular-svg-icons';

const buildActions = (handlers, props) => [
  {
    text: handlers.sliceName ? `New ${handlers.sliceName}` : 'New',
    tooltip: handlers.sliceName && `Create a new ${handlers.sliceName}`,
    isFreeAction: true,
    onClick: () => handlers.new(props),
    toolbarIcon: faPlus,
    toolbarClassName: 'btn-create',
    toolbarButtonPriority: 'primary',
  },
  handlers.assignScores && {
    text: 'Assign Scores',
    isFreeAction: true,
    onClick: () => handlers.assignScoresForWeek(props),
    toolbarClassName: 'btn-create',
    toolbarButtonPriority: 'secondary',
  },
  () =>
    handlers.analytics && {
      color: 'info',
      buttonStyle: 'outline',
      icon: faChartLine,
      isRowAction: true,
      onClick: (_, item) => handlers.analytics(item, props),
    },
  rowItem => {
    const disabled =
      typeof handlers.supportsDelete === 'function'
        ? !handlers.supportsDelete(rowItem, props)
        : !handlers.supportsDelete;
    if (handlers.delete) {
      return {
        title: 'Delete',
        buttonStyle: 'outline',
        color: 'danger',
        icon: faTrashAlt,
        isRowAction: true,
        isSelectedAction: true,
        disabled,
        onClick: (_, item) => handlers.delete(item, props),
        toolbarIcon: faTrashAlt,
        toolbarClassName: 'btn-delete',
        toolbarButtonPriority: 'danger',
      };
    }

    return null;
  },
  // () => {
  //   const {
  //     api: { copy },
  //   } = props;

  //   if (handlers.copy && (copy || handlers.supportsCopy)) {
  //     return {
  //       title: 'Duplicate',
  //       text: 'Duplicate',
  //       icon: faCopy,
  //       isRowAction: true,
  //       onClick: (_, item) => handlers.copy(item, props),
  //     };
  //   }

  //   return null;
  // },
  () =>
    handlers.open && {
      title: 'Open',
      text: 'Open',
      icon: faExternalLink,
      isRowAction: true,
      buttonStyle: 'outline',
      onClick: (_, item) => handlers.open(item),
    },
  () =>
    handlers.edit && {
      title: 'Edit',
      text: 'Edit',
      color: 'primary',
      icon: faPencil,
      isRowAction: true,
      onClick: (_, item) => handlers.edit(item, props),
    },
  // data => {
  //   if (
  //     (!data.url && !(handlers.showOpenUrl && handlers.showOpenUrl(data))) ||
  //     !handlers.openExternal
  //   )
  //     return null;

  //   return {
  //     text: 'View Online',
  //     icon: faExternalLink,
  //     isRowAction: true,
  //     onClick: (_, item) => handlers.openExternal(item, props),
  //   };
  // },
];

export default (handlers, props) =>
  buildActions(
    {
      openExternal: item => {
        window.open(item.url);
        document.activeElement.blur();
      },
      ...handlers,
    },
    props
  );
