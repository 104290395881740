import React from 'react';
import PropTypes from 'prop-types';

/** A tick fixing alignment for area/bar/line charts */

const YAxisTick = props => {
  const { payload, y, formatter, unit } = props;
  return (
    <text
      x={0}
      y={y}
      className="recharts-text recharts-cartesian-axis-tick-value"
    >
      <tspan dy="-4px">
        {formatter ? formatter(payload.value) : payload.value}
        {unit}
      </tspan>
    </text>
  );
};

YAxisTick.propTypes = {
  /** Payload provided by recharts, with prop checking for the values we use */
  payload: PropTypes.shape({
    value: PropTypes.number,
    coordinate: PropTypes.number,
    index: PropTypes.number,
    isShow: PropTypes.bool,
    offset: PropTypes.number,
    tickCoord: PropTypes.number,
  }),
  /** Y position of the tick provided by recharts */
  y: PropTypes.number,
  /** Formatter (kilo, mega, percentage) */
  formatter: PropTypes.func,
  /** Suffix a unit to the value, eg kM */
  unit: PropTypes.string,
};

YAxisTick.defaultProps = {
  payload: null,
  y: 0,
  formatter: null,
  unit: '',
};

export default YAxisTick;
