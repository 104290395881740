import React from 'react';
import PropTypes from 'prop-types';
import { getIn } from 'formik';
import { Input, FormFeedback } from 'reactstrap';
import { isEmpty } from 'lodash';
import { getValidity } from './validate';

const renderField = ({
  field,
  form: { touched = {}, errors = {} },
  ...props
}) => (
  <>
    <Input
      {...props}
      {...field}
      {...getValidity(getIn(touched, field.name), getIn(errors, field.name))}
    />
    {getIn(touched, field.name) &&
      getIn(errors, field.name) &&
      !isEmpty(getIn(errors, field.name)) && (
        <FormFeedback className="validation form__form-group-error" tag="small">
          {getIn(errors, field.name)}
        </FormFeedback>
      )}
  </>
);

renderField.propTypes = {
  field: PropTypes.shape().isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

renderField.defaultProps = {
  placeholder: '',
  meta: null,
  type: 'text',
};

export default renderField;
