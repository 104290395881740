import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Collapse as ReactstrapCollapse } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import './Collapse.scss';

/** Collapsible component for hiding content */

const Collapse = props => {
  const {
    children,
    className,
    customTrigger,
    defaultOpen,
    onTrigger,
    title,
    triggerButtonProps,
  } = props;
  const [isOpen, setIsOpen] = useState(defaultOpen);
  const toggle = () => {
    setIsOpen(!isOpen);
    onTrigger(!isOpen);
    document.activeElement.blur();
  };

  const CollapseTrigger = (
    <div>
      <h4 className="title">{title}</h4>
      <FontAwesomeIcon icon={faChevronDown} className="trigger-icon" />
    </div>
  );

  return (
    <div className={classnames(className, 'collapse-container')}>
      <button
        className={classnames('trigger unstyled-button', {
          open: isOpen,
          closed: !isOpen,
        })}
        color="primary"
        onClick={toggle}
        type="button"
        {...triggerButtonProps}
      >
        {customTrigger || CollapseTrigger}
      </button>
      <ReactstrapCollapse isOpen={isOpen}>
        <div className="collapse-content">{children}</div>
      </ReactstrapCollapse>
    </div>
  );
};

Collapse.propTypes = {
  /** Contents of the collapsible panel */
  children: PropTypes.node,
  /** Custom class for no-standard use cases */
  className: PropTypes.string,
  /** Replace the title with a custom node */
  customTrigger: PropTypes.node,
  /** True if the panel should start open */
  defaultOpen: PropTypes.bool,
  /** Additional trigger click handler */
  onTrigger: PropTypes.func,
  /** Title of the section, always visible */
  title: PropTypes.string,
  /** Additional button props, eg. disabled: true */
  triggerButtonProps: PropTypes.shape(),
};

Collapse.defaultProps = {
  children: null,
  className: '',
  customTrigger: null,
  defaultOpen: false,
  onTrigger: () => {},
  title: '',
  triggerButtonProps: {},
};

export default Collapse;
