import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { CSSTransition } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import './Loading.scss';

/** Loading component that can be shown instead of another component while loading */

const Loading = props => {
  const {
    className,
    maskContainer,
    minHeight,
    size,
    visible,
    delayShow,
  } = props;

  const sizeString = size && `loading-${size}`;

  return (
    <CSSTransition
      in={visible}
      classNames="loading"
      timeout={200}
      unmountOnExit
    >
      <div
        className={classnames(
          'loading-container text-center',
          sizeString,
          className,
          {
            'mask-container': maskContainer,
            'delay-show': delayShow,
          }
        )}
        style={{
          minHeight,
        }}
      >
        <div className="load-icon-wrap" key={1}>
          <FontAwesomeIcon icon={faSpinner} className="load-icon" spin />
        </div>
      </div>
    </CSSTransition>
  );
};

Loading.propTypes = {
  /** For applying custom styles */
  className: PropTypes.string,
  /** Delay the visibility of the component slightly, useful
   * for not showing too quickly on buffering videos */
  delayShow: PropTypes.bool,
  /** Apply a background mask while loading.
   * Requires `position: relative;` and a height on the container. */
  maskContainer: PropTypes.bool,
  /** Apply a min height to the container */
  minHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /** Only render the spinner if visible is true */
  visible: PropTypes.bool,
  /** Spinner size */
  size: PropTypes.oneOf([null, 'sm', 'lg']),
};

Loading.defaultProps = {
  className: null,
  delayShow: false,
  maskContainer: false,
  minHeight: null,
  visible: false,
  size: null,
};

export default Loading;
