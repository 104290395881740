import { has, merge } from 'lodash';
import immutable from 'object-path-immutable';
import { requiresNormalizing } from './normalizeApiResponseMiddleware';

export const states = {
  loading: 'loading',
  loaded: 'loaded',
  error: 'error',
};

const initialState = {};

const handlers = {
  API_SUCCESS: (state, action) => {
    if (!has(action, 'payload.normalized')) {
      return state;
    }

    if (action.meta.clear) {
      return {
        ...initialState,
        entities: action.payload.normalized.entities,
      };
    }

    return merge({}, state, action.payload.normalized.entities);
  },
};

const reducer = (state = initialState, action) => {
  const handler = handlers[action.type];

  if (handler && requiresNormalizing(action)) {
    return handler(state, action);
  }

  if (action.type === 'CLEAR_SESSION') {
    return initialState;
  }

  if (action.type === 'RESET_ENTITIES') {
    if (!has(action, 'payload.except')) {
      return initialState;
    }

    const {
      payload: { except },
    } = action;

    const entityKeys = Object.keys(state).filter(k => except.includes(k));
    return entityKeys.reduce(
      (s, k) => ({
        ...s,
        [k]: state[k],
      }),
      {}
    );
  }

  if (action.type === 'REMOVE_ENTITY' && action.payload) {
    const { meta: { relations = [] } = {} } = action;

    const paths = [
      action.payload.path,
      ...(action.payload.paths || []),
      ...relations,
    ].filter(x => x);

    return paths.reduce(
      (s, r) =>
        immutable(s)
          .del(r)
          .value(),
      state
    );
  }

  if (
    (action.type === 'REPLACE_ENTITIES' || action.type === 'REPLACE_ENTITY') &&
    action.payload
  ) {
    const paths = [...(action.payload.paths || [action.payload])].filter(
      x => x
    );

    return paths.reduce(
      (s, r) =>
        immutable(s)
          .set(r.path, r.entity)
          .value(),
      state
    );
  }

  return state;
};

export default reducer;
