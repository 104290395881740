import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './HeadlineFigure.scss';

/** A component for highlighting stats */

function HeadlineFigure(props) {
  const { color, total, icon, showFractional, subHead, value } = props;
  return (
    <div className="headline-figure">
      <div className="top-line">
        <h3 className={classnames('total-stat', `text-${color}`)}>
          {new Intl.NumberFormat().format(value)}
          {total && showFractional && (
            <span className="denominator">
              /{new Intl.NumberFormat().format(total)}
            </span>
          )}
        </h3>
        <div className={classnames(`text-${color}`, 'stat-icon')}>{icon}</div>
      </div>
      {subHead && <small className="subhead">{subHead}</small>}
    </div>
  );
}

HeadlineFigure.propTypes = {
  /** Main text color */
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'success',
    'danger',
    'warning',
    'info',
    'light',
    'dark',
    null,
  ]),
  /** Total for showing fractions */
  total: PropTypes.number,
  /** Icon on the right of the component */
  icon: PropTypes.node,
  /** Show the total, eg. 10/*50* */
  showFractional: PropTypes.bool,
  /** Context, usually used for units */
  subHead: PropTypes.string,
  /** Highlighted number */
  value: PropTypes.number.isRequired,
};

HeadlineFigure.defaultProps = {
  color: null,
  total: null,
  icon: null,
  showFractional: false,
  subHead: null,
};

export default HeadlineFigure;
