import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './Img.scss';

/** Can be used in place of a HTML `<img />` element, adds some props for a background blur.
 * Could handle loading state and more in future.
 */

const Img = props => {
  const {
    alt,
    blurFill,
    containerClassName,
    imageClassName,
    lightenBackground,
    src,
    title,
  } = props;
  return (
    <div
      className={classnames('image-component', containerClassName, {
        'blur-fill': blurFill,
        lighten: lightenBackground,
      })}
    >
      <div className="image-size-container">
        <img src={src} alt={alt} title={title} className={imageClassName} />
      </div>
      {blurFill && (
        <div
          className="background"
          style={blurFill && { backgroundImage: `url(${src})` }}
        />
      )}
    </div>
  );
};

Img.propTypes = {
  /** HTML alt text attribute */
  alt: PropTypes.string,
  /** Fill the container with a nice blurred background */
  blurFill: PropTypes.bool,
  /** Container div class */
  containerClassName: PropTypes.string,
  /** Class on the element */
  imageClassName: PropTypes.string,
  /** Lighten the blurred background */
  lightenBackground: PropTypes.bool,
  /** HTML image source attribute */
  src: PropTypes.string,
  /** HTML Title attribute, can be the same as alt text */
  title: PropTypes.string,
};

Img.defaultProps = {
  alt: '',
  blurFill: false,
  containerClassName: null,
  imageClassName: null,
  lightenBackground: false,
  src: '',
  title: null,
};

export default Img;
