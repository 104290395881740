import React from 'react';
import { Button as Btn } from 'reactstrap';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './Button.scss';

/** Wraps the Reactstrap button so we can add loading state,
 * and some useful style props to make usage more consistent
 * and reliable.
 */

const Button = ({
  buttonStyle,
  children,
  className,
  color,
  disabled,
  icon,
  iconAfter,
  loading,
  ...props
}) => {
  const bootstrapClasses = {
    link: `text-${color}`,
  };
  const loadingIndicatorVariant = {
    link: 'loading-indicator-link',
    outline: 'loading-indicator-outline',
    solid: 'loading-indicator-solid',
  }[buttonStyle];
  const iconClassName = !children && 'btn-icon';

  return (
    <Btn
      className={classnames(
        bootstrapClasses[buttonStyle],
        className,
        iconClassName
      )}
      color={buttonStyle === 'link' ? 'link' : color}
      disabled={disabled || loading}
      outline={buttonStyle === 'outline'}
      {...props}
    >
      {!iconAfter && icon && icon} {children} {iconAfter && icon && icon}
      {loading && (
        <div
          className={classnames(loadingIndicatorVariant, 'loading-indicator')}
        >
          <div className="ellipsis-container">
            <div className="ellipsis-1" />
            <div className="ellipsis-2" />
            <div className="ellipsis-3" />
            <div className="ellipsis-4" />
          </div>
        </div>
      )}
    </Btn>
  );
};

Button.propTypes = {
  /** Also accepts all the props listed here: https://reactstrap.github.io/components/buttons/ */
  /** Outline can be used for secondary actions */
  buttonStyle: PropTypes.oneOf(['link', 'outline', 'solid']),
  /** Button contents */
  children: PropTypes.node,
  /** Additional classes for non-standard use cases */
  className: PropTypes.string,
  /** Bootstrap colors */
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'success',
    'danger',
    'warning',
    'info',
    'light',
    'dark',
    null,
  ]),
  /** Disabled attribute and class */
  disabled: PropTypes.bool,
  /** Icon note, automatically added so it's always in the same place */
  icon: PropTypes.node,
  /** Place the icon after the text */
  iconAfter: PropTypes.bool,
  /** Loading state */
  loading: PropTypes.bool,
  /** Small, medium or large */
  size: PropTypes.oneOf([null, 'sm', 'lg']),
  /** Html type attribute */
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
};

Button.defaultProps = {
  buttonStyle: 'solid',
  children: null,
  className: null,
  color: null,
  disabled: false,
  icon: null,
  iconAfter: false,
  loading: false,
  size: null,
  type: 'button',
};

export default Button;
