import { createApiReducer } from 'infrastructure/api';

export const initialState = { items: [] };

const handlers = {
  'system/menu/GET': (state, payload) => payload,
};

const reducer = createApiReducer(handlers, initialState);

export default reducer;
