import React from 'react';
import { PieChart } from 'components/Charts';
import './ProgressCircle.scss';
import variables from 'scss/1-settings/colors.scss';
import PropTypes from 'prop-types';

const colorOptions = {
  primary: variables.primary,
  secondary: variables.secondary,
  success: variables.success,
  info: variables.info,
  warning: variables.warning,
  danger: variables.danger,
  light: variables.light,
  dark: variables.dark,
};

function ProgressCircle(props) {
  const { color, value } = props;

  const data01 = [
    { value, fill: colorOptions[color] },
    { value: 100 - value, fill: 'rgba(125, 125, 125, 0.15)' },
  ];
  return (
    <PieChart
      aspect={1}
      data={data01}
      innerRadius="75%"
      isAnimationActive={false}
      label={false}
      outerRadius="100%"
      showLegend={false}
      tooltip={false}
      endAngle={-90}
      startAngle={270}
    />
  );
}

ProgressCircle.propTypes = {
  /** Bootstrap color */
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'success',
    'info',
    'warning',
    'danger',
    'light',
    'dark',
  ]),
  /** Progress value */
  value: PropTypes.number.isRequired,
};

ProgressCircle.defaultProps = {
  color: 'primary',
};

export default ProgressCircle;
