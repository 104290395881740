import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Draggable } from 'react-beautiful-dnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMinusCircle,
  faGripVertical,
} from '@fortawesome/pro-solid-svg-icons';
import Button from 'components/Button';
import { visibility } from 'shared/enhancers';

const SingleFieldItems = ({ Component, name, values, remove, ...props }) => (
  <React.Fragment>
    {values.map((_, index) => (
      <Draggable
        key={`${name + index}`}
        draggableId={`${name + index}`}
        index={index}
      >
        {(p, s) => (
          <div
            className={classnames('single-field', {
              active: s.isDragging,
            })}
            key={`${name + index}`}
            ref={p.innerRef}
            {...p.draggableProps}
            {...p.dragHandleProps}
          >
            <FontAwesomeIcon
              icon={faGripVertical}
              className="icon drag-handle"
            />
            <Component {...props} index={index} name={`${name}[${index}]`} />
            <Button
              aria-label="Remove"
              className="btn-light icon fieldset-right-icon rounded-pill"
              buttonStyle="link"
              onClick={() => {
                remove(index);
                document.activeElement.blur();
              }}
              outline
              color="danger"
              title="Remove"
              icon={
                <FontAwesomeIcon icon={faMinusCircle} className="text-danger" />
              }
            />
          </div>
        )}
      </Draggable>
    ))}
  </React.Fragment>
);
SingleFieldItems.propTypes = {
  Component: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  values: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  remove: PropTypes.func.isRequired,
};

export default visibility(SingleFieldItems);
