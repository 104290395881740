import React from 'react';
import PropTypes from 'prop-types';
import Notification from 'rc-notification';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faInfoCircle,
  faCheckCircle,
  faExclamationCircle,
  faExclamationTriangle,
} from '@fortawesome/pro-solid-svg-icons';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import './Toast.scss';

/** An alert component. */

const close = () => <FontAwesomeIcon icon={faTimes} size="lg" />;

let notification = null;

Notification.newInstance(
  {
    closeIcon: close(),
    maxCount: 1,
  },
  n => {
    notification = n;
  }
);

const sharedOptions = {
  duration: 7,
  closable: true,
  style: {},
  maxCount: 1,
};

const icon = {
  info: <FontAwesomeIcon icon={faInfoCircle} />,
  success: <FontAwesomeIcon icon={faCheckCircle} />,
  warning: <FontAwesomeIcon icon={faExclamationCircle} />,
  danger: <FontAwesomeIcon icon={faExclamationTriangle} />,
};

const AlertContent = ({ msg, title, color }) => (
  <React.Fragment>
    <div className="alert-icon">{icon[color]}</div>
    <div className="alert-content">
      {title && <strong>{title}</strong>}
      <p>{msg}</p>
    </div>
  </React.Fragment>
);

AlertContent.propTypes = {
  /** Alert details */
  msg: PropTypes.string.isRequired,
  /** Alert headlines */
  title: PropTypes.string,
  /** Color of the alert */
  color: PropTypes.string.isRequired,
};

AlertContent.defaultProps = {
  title: '',
};

export { AlertContent };

export default {
  error: (msg, title = 'Error') =>
    notification.notice({
      content: (
        <AlertContent color="danger" msg={msg} title={title} icon={icon} />
      ),
      className: 'alert alert-danger',
      ...sharedOptions,
    }),
  info: (msg, title) =>
    notification.notice({
      content: (
        <AlertContent color="info" msg={msg} title={title} icon={icon} />
      ),
      className: 'alert alert-info',
      ...sharedOptions,
    }),
  success: (msg, title = 'Success') =>
    notification.notice({
      content: (
        <AlertContent color="success" msg={msg} title={title} icon={icon} />
      ),
      className: 'alert alert-success',
      ...sharedOptions,
    }),
  warning: (msg, title) =>
    notification.notice({
      content: (
        <AlertContent color="warning" msg={msg} title={title} icon={icon} />
      ),
      className: 'alert alert-warning',
      ...sharedOptions,
    }),
};
