import { takeEvery, call } from 'redux-saga/effects';
import { toast } from 'components';

export function* handle500(action) {
  if (
    action.payload &&
    action.payload.response &&
    action.payload.response.error &&
    action.payload.response.error.message
  ) {
    yield call(() => toast.error(action.payload.response.error.message));
  } else {
    yield call(() => toast.error(action.payload.message));
  }
}

function* erroListener() {
  yield takeEvery(
    x =>
      (x.type === 'API_FAILURE' && x.payload.status === 500) ||
      (x.type === 'API_REQUEST' && x.error),
    handle500
  );
}

export default erroListener;
