import React from 'react';
import { camelCase } from 'lodash';
import { Col, Label, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './FormRow.scss';

/** A FormRow component for attaching labels to inputs and building forms */

const FormRow = props => {
  const {
    align,
    borderless,
    children,
    className,
    helpText,
    label,
    name,
    optional,
    stacked,
    toggleContainer,
    toggleLeft,
  } = props;

  const htmlFor = name || (label && camelCase(label.replace(/\s+/, '')));

  const inlineLabelWidth = toggleLeft ? '' : 3;

  return (
    <div
      className={classnames(
        className,
        styles.formRow,
        'form__form-group',
        {
          borderless,
          optional,
          'toggle-container': toggleContainer,
          'toggle-left': toggleLeft,
        },
        stacked ? 'stacked' : 'horizontal'
      )}
    >
      <Row
        className={classnames('form-row', {
          'align-items-top': align === 'top' && !toggleContainer,
          'align-items-center': align === 'center' || toggleContainer,
          'no-gutters': toggleLeft,
        })}
      >
        {label && (
          <Label
            className={classnames(
              { 'order-2 ml-3': toggleLeft },
              'form__form-group-label'
            )}
            htmlFor={htmlFor}
            md={stacked ? 12 : inlineLabelWidth}
            xs={toggleContainer && 'auto'}
          >
            {label}
            {optional && (
              <small
                className={classnames('help-text optional-flag', {
                  'ml-1': stacked,
                  'd-block': !stacked,
                })}
              >
                (Optional)
              </small>
            )}
          </Label>
        )}
        <Col
          xs={(toggleContainer && 'auto') || 12}
          md={!toggleLeft && (!label || stacked ? 12 : 9)}
        >
          <div className="input-container">
            <div className="form__form-group-input-wrap--error-below">
              {children}
              {helpText && (
                <small className="help-text d-block mt-1">{helpText}</small>
              )}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

FormRow.propTypes = {
  /** Vertical alignment of the label, `top` is useful for taller inputs */
  align: PropTypes.oneOf(['top', 'center']),
  /** Remove form row borders */
  borderless: PropTypes.bool,
  /** Input and additional content */
  children: PropTypes.node.isRequired,
  /** Custom style class */
  className: PropTypes.string,
  /** Additional info to aid user understanding */
  helpText: PropTypes.string,
  /** Form input label */
  label: PropTypes.node,
  /** Input name & ID */
  name: PropTypes.string,
  /** If the input is optional? */
  optional: PropTypes.bool,
  /** Stack the label above the input? */
  stacked: PropTypes.bool,
  /** Contains a checkbox, radio button or toggle */
  toggleContainer: PropTypes.bool,
  /** Move the toggle or checkbox to the left */
  toggleLeft: PropTypes.bool,
};

FormRow.defaultProps = {
  align: 'top',
  borderless: false,
  className: null,
  helpText: '',
  label: null,
  name: '',
  optional: false,
  stacked: false,
  toggleContainer: false,
  toggleLeft: false,
};

export default FormRow;
