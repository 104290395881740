import React from 'react';
import {
  PieChart as Rechart,
  Pie,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import PropTypes from 'prop-types';
import './PieChart.scss';

const renderLegend = ({ payload }) => (
  <ul className="chart-legend">
    {payload.map((entry, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <li key={`item-${index}`}>
        <span
          className="legend-color-sample"
          style={{ backgroundColor: entry.color }}
        />
        {entry.value}{' '}
        <small className="text-muted font-weight-light">
          ({entry.payload.value})
        </small>
      </li>
    ))}
  </ul>
);

renderLegend.propTypes = {
  payload: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
};

/** A wrapper for Recharts PieChart. */

const PieChart = props => {
  const {
    aspect,
    cx,
    cy,
    data,
    endAngle,
    innerRadius,
    label,
    outerRadius,
    showLegend,
    startAngle,
    tooltip,
  } = props;
  return (
    data &&
    data.length && (
      <ResponsiveContainer
        className="chart pie-chart responsive"
        aspect={aspect}
      >
        <Rechart margin={{ top: 0, right: 0, bottom: 0, left: 0 }}>
          {tooltip && <Tooltip />}
          <Pie
            {...props}
            cx={cx}
            cy={cy}
            data={data}
            dataKey="value"
            innerRadius={innerRadius}
            label={label}
            endAngle={endAngle}
            outerRadius={outerRadius}
            startAngle={startAngle}
            stroke="none"
          />
          {showLegend && (
            <Legend
              className="chart-legend"
              layout="vertical"
              verticalAlign="bottom"
              content={renderLegend}
            />
          )}
        </Rechart>
      </ResponsiveContainer>
    )
  );
};

PieChart.propTypes = {
  /** Aspect ratio, define the horizontal width as a multiple of the height (eg. 0.5 or 3) */
  aspect: PropTypes.number,
  /** Left position for the center of the pie in px if number, or percentage (defaults to '50%') */
  cx: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /** Top position for the center of the pie in px if number, or percentage (defaults to '50%') */
  cy: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /** Pie chart data, see story */
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.number,
      fill: PropTypes.string, // Worth importing variables from scss in use.
    })
  ).isRequired,
  /** Can be used to create half pie charts etc */
  endAngle: PropTypes.number,
  /** For making a ring chart, value example: '50%' */
  innerRadius: PropTypes.string,
  /** Label segments */
  label: PropTypes.bool,
  /** Can be reduced to fit labels in the container */
  outerRadius: PropTypes.string,
  /** Show definition list with values */
  showLegend: PropTypes.bool,
  /** Can be used to create half pie charts etc */
  startAngle: PropTypes.number,
  /** Show labels on hover */
  tooltip: PropTypes.bool,
};

PieChart.defaultProps = {
  aspect: 0.6,
  cx: null,
  cy: null,
  endAngle: 90,
  innerRadius: null,
  label: true,
  outerRadius: null,
  showLegend: true,
  startAngle: 450,
  tooltip: true,
};

export default PieChart;
