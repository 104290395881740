import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useDropzone } from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMinusCircle,
  faVideo,
  faFile,
} from '@fortawesome/pro-solid-svg-icons';
import Button from 'components/Button';
import { Img } from 'components';

const DropZoneField = props => {
  const {
    accept,
    className,
    customRemoveFile,
    dropZoneShape,
    emptyMessage,
    fieldProps,
    maxSize,
    multiple,
    name,
    onChange,
    onDropRejected,
    previewShape,
  } = props;

  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    accept,
    onDrop: acceptedFiles => {
      setFiles(
        files.concat(
          acceptedFiles.map(file => {
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            });
            return file;
          })
        )
      );
      onChange(acceptedFiles);
    },
    onDropRejected,
  });

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach(file => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  const removeFile = (file, e) => {
    const newFiles = [...files];
    newFiles.splice(file, 1);
    setFiles(newFiles);
    e.stopPropagation();
  };

  const previewContents = file => {
    switch (true) {
      case file.type.startsWith('image'):
        return (
          <Img
            alt={file.name}
            blurFill={!dropZoneShape}
            containerClassName="file-thumbnail"
            imageClassName={dropZoneShape && 'crop-img'}
            lightenBackground
            src={file.preview}
          />
        );
      case file.type.startsWith('video'):
        return <FontAwesomeIcon icon={faVideo} className="placeholder-icon" />;
      default:
        return <FontAwesomeIcon icon={faFile} className="placeholder-icon" />;
    }
  };

  return (
    <div
      {...getRootProps({
        className: classnames('dropzone form-control', className, {
          'dropzone-circle': dropZoneShape === 'circle',
          'dropzone-square': dropZoneShape === 'square',
          'dropzone-portrait': dropZoneShape === 'portrait',
          'dropzone-landscape': dropZoneShape === 'landscape',
        }),
      })}
      {...fieldProps}
    >
      <input
        {...getInputProps()}
        className="dropzone-input"
        id={name}
        maxsize={maxSize} // 64MB
        multiple={multiple}
        name={name}
      />
      {(!files || files.length === 0) && (
        <div className="dropzone-drop-here">{emptyMessage}</div>
      )}

      {files && Array.isArray(files) && files.length > 0 && (
        <div className="files-wrapper">
          {files.map((file, i) => (
            <div
              className={classnames('file-preview', {
                'preview-circle': previewShape === 'circle',
                'preview-square': previewShape === 'square',
                'preview-portrait': previewShape === 'portrait',
                'preview-landscape': previewShape === 'landscape',
              })}
              // eslint-disable-next-line react/no-array-index-key
              key={i}
            >
              {previewContents(file)}
              <div className="file-overlay">
                <small className="file-name">{file.name}</small>
                <Button
                  className={classnames('remove', {
                    'rounded-circle': !dropZoneShape,
                  })}
                  color="danger"
                  icon={<FontAwesomeIcon icon={faMinusCircle} />}
                  onClick={e => {
                    removeFile(i, e);
                    if (customRemoveFile) {
                      customRemoveFile();
                    }
                  }}
                  size="sm"
                >
                  {dropZoneShape && 'Remove'}
                </Button>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

DropZoneField.propTypes = {
  accept: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string,
  customRemoveFile: PropTypes.func,
  dropZoneShape: PropTypes.oneOf([
    null,
    'circle',
    'square',
    'portrait',
    'landscape',
  ]),
  emptyMessage: PropTypes.node.isRequired,
  fieldProps: PropTypes.shape({}),
  maxSize: PropTypes.number,
  multiple: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onDropRejected: PropTypes.func,
  previewShape: PropTypes.oneOf([
    null,
    'circle',
    'square',
    'portrait',
    'landscape',
  ]),
};

DropZoneField.defaultProps = {
  accept: [
    'image/jpg',
    'image/jpeg',
    'image/png',
    'video/mp4',
    'video/quicktime',
  ],
  className: '',
  customRemoveFile: null,
  dropZoneShape: null,
  fieldProps: null,
  maxSize: 64000000,
  onChange: null,
  onDropRejected: null,
  previewShape: null,
};

const renderDropZoneField = props => {
  const {
    accept,
    className,
    customRemoveFile,
    dropZoneShape,
    emptyMessage,
    fieldProps,
    input,
    maxSize,
    multiple,
    onDropRejected,
    previewShape,
  } = props;
  return (
    <DropZoneField
      {...input}
      accept={accept}
      className={className}
      dropZoneShape={dropZoneShape}
      emptyMessage={emptyMessage}
      fieldProps={fieldProps}
      maxSize={maxSize}
      multiple={multiple}
      customRemoveFile={customRemoveFile}
      onDropRejected={onDropRejected}
      previewShape={previewShape}
    />
  );
};

renderDropZoneField.propTypes = {
  accept: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string,
  dropZoneShape: PropTypes.oneOf([
    null,
    'circle',
    'square',
    'portrait',
    'landscape',
  ]),
  emptyMessage: PropTypes.node.isRequired,
  fieldProps: PropTypes.shape({}),
  customRemoveFile: PropTypes.func,
  input: PropTypes.shape({
    name: PropTypes.string,
    onChange: PropTypes.func,
  }).isRequired,
  maxSize: PropTypes.number,
  multiple: PropTypes.bool,
  onDropRejected: PropTypes.func,
  previewShape: PropTypes.oneOf([
    null,
    'circle',
    'square',
    'portrait',
    'landscape',
  ]),
};

renderDropZoneField.defaultProps = {
  accept: [
    'image/jpg',
    'image/jpeg',
    'image/png',
    'video/mp4',
    'video/quicktime',
  ],
  className: '',
  customRemoveFile: null,
  dropZoneShape: null,
  fieldProps: null,
  maxSize: 64000000,
  multiple: false,
  onDropRejected: null,
  previewShape: null,
};

export default renderDropZoneField;
