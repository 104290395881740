import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Tooltip as ReactstrapTooltip } from 'reactstrap';

import './Tooltip.scss';

/**
 * A wrapper that can be used to add a tooltip to an element.
 * Added so we don't need to deal with state every time we use a Tooltip.
 */

class Tooltip extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      tooltipOpen: false,
    };
  }

  toggle() {
    const { tooltipOpen } = this.state;
    this.setState({
      tooltipOpen: !tooltipOpen,
    });
  }

  render() {
    const { tooltipOpen } = this.state;
    const { children, id, placement, tooltipContents } = this.props;

    return (
      <React.Fragment>
        <span id={id} className="tooltip-container">
          {children}
        </span>
        <ReactstrapTooltip
          placement={placement}
          isOpen={tooltipOpen}
          target={id}
          autohide={false}
          toggle={this.toggle}
        >
          {tooltipContents}
        </ReactstrapTooltip>
      </React.Fragment>
    );
  }
}

Tooltip.propTypes = {
  /** Contents that will display the tooltip when hovered on */
  children: PropTypes.node.isRequired,
  /** Where to place the tooltip */
  placement: ReactstrapTooltip.propTypes.placement,
  /** A unique ID is required to bind the trigger and the tooltip */
  id: PropTypes.string.isRequired,
  /** The contents of the tooltip */
  tooltipContents: PropTypes.node.isRequired,
};

Tooltip.defaultProps = {
  placement: 'top',
};

export default Tooltip;
