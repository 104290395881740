import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { Button, Modal } from 'components';

const DeleteModal = ({
  title,
  onDelete,
  onCancel,
  isPublished,
  entityName,
}) => {
  const [isDeleting, setIsDeleting] = useState(false);
  return (
    <Modal
      hideButton
      show
      color="danger"
      confirmationModal
      onCancel={onCancel}
      size="sm"
      footer={
        <Row>
          <Col xs="auto">
            <Button
              color="dark"
              icon={<FontAwesomeIcon icon={faTimes} />}
              onClick={onCancel}
              outline
              disabled={isDeleting}
            >
              Cancel
            </Button>
          </Col>
          <Col xs="auto">
            <Button
              autofocus="true"
              color="danger"
              icon={<FontAwesomeIcon icon={faTrashAlt} />}
              onClick={() => {
                setIsDeleting(true);
                onDelete();
              }}
              loading={isDeleting}
            >
              Yes, Delete
            </Button>
          </Col>
        </Row>
      }
    >
      <h3>
        {isPublished
          ? `${title} is live.
      This will immediately remove the ${entityName} from the app.`
          : `Are you sure you want to delete ${title}?`}
      </h3>
    </Modal>
  );
};

DeleteModal.propTypes = {
  title: PropTypes.string,
  onDelete: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  isPublished: PropTypes.bool,
  entityName: PropTypes.string,
};

DeleteModal.defaultProps = {
  title: null,
  onCancel: null,
  isPublished: false,
  entityName: null,
};

export default DeleteModal;
