import React from 'react';
import { CardText } from 'reactstrap';
import { Panel } from 'components';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import BalanceText from 'react-balance-text';
import './UIMessage.scss';

/** To be used when there's no data for a component or page */

function UIMessage({ className, title, image, message, cta }) {
  return (
    <Panel body className={classnames('ui-message', className)}>
      {image && <img src={image} alt="" className="artwork" />}
      {typeof title === 'string' ? (
        <h2>
          <BalanceText>{title}</BalanceText>
        </h2>
      ) : (
        title
      )}
      {typeof message === 'string' ? (
        <CardText>
          <BalanceText>{message}</BalanceText>
        </CardText>
      ) : (
        message
      )}
      {cta}
    </Panel>
  );
}

UIMessage.propTypes = {
  /** Container class */
  className: PropTypes.string,
  /** Button or link to lead the user on to a desired flow */
  cta: PropTypes.node,
  /** Message to give more context */
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /** Title of the blank slate or a very brief description of where the user is to give context */
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /** Artwork image src if available */
  image: PropTypes.string,
};

UIMessage.defaultProps = {
  className: null,
  cta: null,
  message: null,
  title: null,
  image: null,
};

export default UIMessage;
