import { uniq } from 'lodash';
import states from './states';

const update = (items, u) => {
  const result = uniq(u(items));

  return {
    totalCount: result.length,
    result,
  };
};

export default ({
  GET_LIST,
  GET_ADMIN_LIST,
  GET_STATS,
  GET_BY_PAGE_STATS,
  GET_SETTINGS,
  DELETE,
  UPDATE,
  GET,
  GET_ADMIN,
  CREATE,
  GET_PUBLIC_PREVIEW,
  TOGGLE_PREVIEW_IS_PUBLIC,
  UPDATE_TITLE,
  ADD_TRACKING_PIXEL,
  UPDATE_THUMBNAIL,
  UPDATE_ROUND_IMAGE,
  UPDATE_SCHEDULE,
  TOGGLE_SHOW_FIRST,
}) => ({
  [GET_LIST]: (_, payload) => ({
    result: payload.normalized.result,
    totalCount: payload.normalized.result.length,
    status: states.loaded,
  }),
  [GET_ADMIN_LIST]: (_, payload) => ({
    result: payload.normalized.result,
    totalCount: payload.normalized.result.length,
    status: states.loaded,
  }),
  [GET_STATS]: ({ ...state }, payload) => ({
    ...state,
    stats: payload.items,
  }),
  [GET_BY_PAGE_STATS]: ({ ...state }, payload) => ({
    ...state,
    byPageStats: payload.pages,
  }),
  [GET_SETTINGS]: (_, payload) => ({
    ...payload,
  }),
  [DELETE]: ({ result, ...state }, _, meta) => ({
    ...state,
    ...update(result, i => i.filter(x => x !== meta.id)),
    status: states.loaded,
  }),
  [CREATE]: ({ result, ...state }, payload) => ({
    ...state,
    ...update(result, i => [...i, payload.id]),
    status: states.loaded,
  }),
  [UPDATE]: ({ result, ...state }, payload) => ({
    ...state,
    ...update(result, i => [...i, payload.id]),
    status: states.loaded,
  }),
  [UPDATE_TITLE]: ({ result, ...state }, payload) => ({
    ...state,
    ...update(result, i => [...i, payload.id]),
    status: states.loaded,
  }),
  [GET]: ({ result, ...state }, payload) => ({
    ...state,
    ...update(result, i => [...i, payload.id]),
    status: states.loaded,
  }),
  [GET_PUBLIC_PREVIEW]: ({ result, ...state }, payload) => ({
    ...state,
    ...update(result, i => [...i, payload.id]),
    status: states.loaded,
  }),
  [TOGGLE_PREVIEW_IS_PUBLIC]: ({ result, ...state }, payload) => ({
    ...state,
    ...update(result, i => [...i, payload.id]),
    status: states.loaded,
  }),
  [TOGGLE_SHOW_FIRST]: ({ result, ...state }, payload) => ({
    ...state,
    ...update(result, i => [...i, payload.id]),
    status: states.loaded,
  }),
  [GET_ADMIN]: ({ result, ...state }, payload) => ({
    ...state,
    ...update(result, i => [...i, payload.id]),
    status: states.loaded,
  }),
  [ADD_TRACKING_PIXEL]: ({ result, ...state }, payload) => ({
    ...state,
    ...update(result, i => [...i, payload.id]),
    status: states.loaded,
  }),
  [UPDATE_THUMBNAIL]: ({ result, ...state }, payload) => ({
    ...state,
    ...update(result, i => [...i, payload.id]),
    status: states.loaded,
  }),
  [UPDATE_ROUND_IMAGE]: ({ result, ...state }, payload) => ({
    ...state,
    ...update(result, i => [...i, payload.id]),
    status: states.loaded,
  }),
  [UPDATE_SCHEDULE]: ({ result, ...state }, payload) => ({
    ...state,
    ...update(result, i => [...i, payload.id]),
    status: states.loaded,
  }),
  default: state => state,
});
