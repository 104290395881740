import { isString } from 'lodash';

export const idAttribute = value => (isString(value) ? value : value.id);

export const addParent = (prop = 'parent') => (value, parent) => ({
  ...value,
  [prop]: parent.id,
});

export const initialState = {
  items: [],
  state: '',
  sort: {
    sortName: '',
    sortOrder: '',
  },
};
