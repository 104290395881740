/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { useState, useEffect } from 'react';
import { Content, SubHeading, Button, Loading } from 'components';
import Panel from 'components/Panel/Panel';
import { Col, Row, Input, FormGroup, Label } from 'reactstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { compose } from 'recompose';
import { withApi } from 'infrastructure/api';
import injectReducer from 'infrastructure/redux/injectReducer';
import ErrorAlert from 'infrastructure/error/components/ErrorMessage';
import { createStructuredSelector } from 'reselect';
import { stateKey } from './components/constants';
import reducer from './components/reducer';
import makeSelectStats from './components/selectors';
import './index.scss';
import TotalCount from './components/TotalCount';

function mapDispatchToProps(dispatch, props) {
  return {
    getOverview: bindActionCreators(
      props.api.app.dashboard.getOverview,
      dispatch
    ),
    getPackInformation: bindActionCreators(
      props.api.app.dashboard.getPackInformation,
      dispatch
    ),
    getPolls: bindActionCreators(props.api.app.dashboard.getPolls, dispatch),
    getAdditionalInformation: bindActionCreators(
      props.api.app.dashboard.getAdditionalInformation,
      dispatch
    ),
    getBadges: bindActionCreators(props.api.app.dashboard.getBadges, dispatch),
    getCompletedPackInformation: bindActionCreators(
      props.api.app.dashboard.getCompletedPackInformation,
      dispatch
    ),
    getQuestionPackInformation: bindActionCreators(
      props.api.app.dashboard.getQuestionPackInformation,
      dispatch
    ),
    getGoogleAnalytics: bindActionCreators(
      props.api.app.dashboard.getGoogleAnalytics,
      dispatch
    ),
  };
}

const mapStateToProps = createStructuredSelector({
  [stateKey]: makeSelectStats(),
});

const withReducer = injectReducer({ key: stateKey, reducer });
const withDomain = connect(mapStateToProps, mapDispatchToProps);

const enhance = compose(withApi, withReducer, withDomain);

const Dashboard = props => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [overview, setOverview] = useState(null);
  const [packInformation, setPackInformation] = useState(null);
  const [questionPackInformation, setQuestionPackInformation] = useState(null);
  const [completedPackInformation, setCompletedPackInformation] = useState(
    null
  );
  const [additionalInformation, setAdditionalInformation] = useState(null);
  const [polls, setPolls] = useState(null);
  const [badges, setBadges] = useState(null);
  const [analytics, setAnalytics] = useState(null);
  const [
    loadingPackInformationSection,
    setLoadingPackInformationSection,
  ] = useState(false);
  const [loadingPackInformation, setLoadingPackInformation] = useState(false);
  const [
    loadingQuestionPackInformation,
    setLoadingQuestionPackInformation,
  ] = useState(false);
  const [
    loadingCompletedPackInformation,
    setLoadingCompletedPackInformation,
  ] = useState(false);

  useEffect(() => {
    if (
      loadingPackInformation === false &&
      loadingQuestionPackInformation === false &&
      loadingCompletedPackInformation === false
    ) {
      setLoadingPackInformationSection(false);
    }
  }, [
    loadingPackInformation,
    loadingQuestionPackInformation,
    loadingCompletedPackInformation,
  ]);

  const [
    loadingAdditionalInformation,
    setLoadingAdditionalInformation,
  ] = useState(false);
  const [loadingPolls, setLoadingPolls] = useState(false);
  const [loadingBadges, setLoadingBadges] = useState(false);
  const [loadingAnalytics, setLoadingAnalytics] = useState(false);
  const [loadingOverview, setLoadingOverview] = useState(false);

  const renderLoadingComponent = loadingText => (
    <div
      style={{ height: '200px', position: 'relative', marginBottom: '15px' }}
    >
      <SubHeading subHeading={loadingText} />
      <Loading visible maskContainer />
    </div>
  );

  const renderPackInformation = (
    packInformationResponse,
    questionPackInformationResponse,
    completedQuestionPackResponse
  ) => {
    if (loadingPackInformationSection) {
      return renderLoadingComponent('Loading Pack Information');
    }
    if (!packInformationResponse) {
      return null;
    }
    if (packInformationResponse.type !== 'API_SUCCESS') {
      return (
        <div>
          <ErrorAlert
            canRetry={false}
            message="There was an error loading pack information"
          />
        </div>
      );
    }

    const packInformationPayload = packInformationResponse.payload;
    const questionPackInformationPayload =
      questionPackInformationResponse.payload;
    const completedPackInformationPayload =
      completedQuestionPackResponse.payload;

    return (
      <div>
        <SubHeading subHeading="Pack Information" />
        <Row className="mb-4">
          <Col md={6} xl={6} lg={6} xs={12}>
            <Panel title="Completed Packs">
              <TotalCount
                total={completedPackInformationPayload.completedPacks}
              />
            </Panel>
          </Col>
          <Col md={6} xl={6} lg={6} xs={12}>
            <Panel title="Completion Percentage">
              <TotalCount
                total={completedPackInformationPayload.completionPercentage}
              />
            </Panel>
          </Col>
          <Col md={6} xl={6} lg={6} xs={12}>
            <Panel title="Total Answered Questions">
              <TotalCount total={packInformationPayload.anseredQuestions} />
            </Panel>
          </Col>
          <Col md={6} xl={6} lg={6} xs={12}>
            <Panel title="Correct Answer Percentage">
              <TotalCount
                total={packInformationPayload.correctAnsweredPercentage}
              />
            </Panel>
          </Col>
          <Col md={12} xl={4} lg={4} xs={12}>
            <Panel title="Daily Trivia Completed Packs">
              <TotalCount
                total={
                  questionPackInformationPayload.dailyTriviaQuestionPackCount
                }
              />
            </Panel>
          </Col>
          <Col md={12} xl={4} lg={4} xs={12}>
            <Panel title="General Knowledge Completed Packs">
              <TotalCount
                total={questionPackInformationPayload.gkQuestionPackCount}
              />
            </Panel>
          </Col>
          <Col md={12} xl={4} lg={4} xs={12}>
            <Panel title="Hallmark+ Completed Packs">
              <TotalCount
                total={
                  questionPackInformationPayload.hallmarkPlusQuestionPackCount
                }
              />
            </Panel>
          </Col>
          <Col md={12} xl={4} lg={4} xs={12}>
            <Panel title="Premiere Play Completed Packs">
              <TotalCount
                total={
                  questionPackInformationPayload.premierCompletedQuestionPack
                }
              />
            </Panel>
          </Col>
        </Row>
      </div>
    );
  };

  const renderTopTriviaPacks = dashboard => {
    if (dashboard && dashboard.topTriviaPacks) {
      return dashboard.topTriviaPacks.map(item => (
        <Col md={4} xl={4} lg={4} xs={12}>
          <Panel title={item.title}>
            <TotalCount total={item.count} />
          </Panel>
        </Col>
      ));
    }
    return null;
  };

  const renderTopPremierePlaysPacks = dashboard => {
    if (dashboard && dashboard.topPremierePlays.length > 0) {
      return (
        <>
          <SubHeading subHeading="Top Premiere Plays by Check In" />
          <Row>
            {dashboard.topPremierePlays.map(item => (
              <Col md={6} xl={6} lg={6} xs={12}>
                <Panel title={item.title}>
                  <TotalCount total={item.count} />
                </Panel>
              </Col>
            ))}
          </Row>
        </>
      );
    }

    return null;
  };

  const renderTopBadges = dashboard => {
    if (dashboard && dashboard.topBadges) {
      return dashboard.topBadges.map(item => (
        <Col md={6} xl={6} lg={6} xs={12}>
          <Panel title={item.title}>
            <TotalCount total={item.count} />
          </Panel>
        </Col>
      ));
    }
    return null;
  };

  const renderLimitedEditionBadges = dashboard => {
    if (dashboard && dashboard.limitedEditionTopBadges) {
      return dashboard.limitedEditionTopBadges.map(item => (
        <Col md={6} xl={6} lg={6} xs={12}>
          <Panel title={item.title}>
            <TotalCount total={item.count} />
          </Panel>
        </Col>
      ));
    }
    return null;
  };

  const renderOverview = overviewResponse => {
    if (loadingOverview) {
      return renderLoadingComponent('Loading Overview');
    }
    if (!overviewResponse) {
      return null;
    }
    if (overviewResponse.type !== 'API_SUCCESS') {
      return (
        <div>
          <ErrorAlert
            canRetry={false}
            message="There was an error loading overview data"
          />
        </div>
      );
    }

    const overviewPayload = overviewResponse.payload;

    return (
      <div>
        <SubHeading subHeading="Overview" />
        <Row className="mb-4">
          <Col md={6} xl={6} lg={6} xs={12}>
            <Panel title="Active Users">
              <TotalCount total={overviewPayload.activeUsers} />
            </Panel>
          </Col>
          <Col md={6} xl={6} lg={6} xs={12}>
            <Panel title="Signups">
              <TotalCount total={overviewPayload.signups} />
            </Panel>
          </Col>
          <Col md={6} xl={6} lg={6} xs={12}>
            <Panel title="Total Entries">
              <TotalCount total={overviewPayload.totalEntries} />
            </Panel>
          </Col>
          <Col md={6} xl={6} lg={6} xs={12}>
            <Panel title="Checkins">
              <TotalCount total={overviewPayload.checkins} />
            </Panel>
          </Col>
          {/* <Col md={6} xl={6} lg={6} xs={12}>
            <Panel title="Sponsor Opt-Ins">
              <TotalCount total={overviewPayload.userSponsorOptedInCount} />
            </Panel>
          </Col> */}
        </Row>
      </div>
    );
  };

  const renderPolls = pollsResponse => {
    if (loadingPolls) {
      return renderLoadingComponent('Loading Polls');
    }
    if (!pollsResponse) {
      return null;
    }
    if (pollsResponse.type !== 'API_SUCCESS') {
      return (
        <div>
          <ErrorAlert
            canRetry={false}
            message="There was an error loading poll data"
          />
        </div>
      );
    }

    const pollsPayload = pollsResponse.payload;

    return (
      <div>
        <SubHeading subHeading="Polls" />
        <Row className="mb-4">
          <Col md={4} xl={4} lg={4} xs={12}>
            <Panel title="Daily Polls Answered">
              <TotalCount total={pollsPayload.dailyPollsCount} />
            </Panel>
          </Col>
          <Col md={4} xl={4} lg={4} xs={12}>
            <Panel title="Premiere Polls Answered">
              <TotalCount total={pollsPayload.premierePlayPollsCount} />
            </Panel>
          </Col>
          <Col md={4} xl={4} lg={4} xs={12}>
            <Panel title="Live Premiere Polls Answered">
              <TotalCount total={pollsPayload.livePremierePlayPollCount} />
            </Panel>
          </Col>
        </Row>
      </div>
    );
  };

  const renderAdditionalInformation = additionalInformationResponse => {
    if (loadingAdditionalInformation) {
      return renderLoadingComponent('Loading Additional Information');
    }
    if (!additionalInformationResponse) {
      return null;
    }
    if (additionalInformationResponse.type !== 'API_SUCCESS') {
      return (
        <div>
          <ErrorAlert
            canRetry={false}
            message="There was an error loading additional information data"
          />
        </div>
      );
    }

    const information = additionalInformationResponse.payload;

    return (
      <div>
        <SubHeading subHeading="Bonuses, Groups & Word Searches" />
        <Row className="mb-4">
          <Col md={4} xl={4} lg={4} xs={12}>
            <Panel title="Bonuses Claimed">
              <TotalCount total={information.claimedRewards} />
            </Panel>
          </Col>
          <Col md={4} xl={4} lg={4} xs={12}>
            <Panel title="Groups Created">
              <TotalCount total={information.groupsCreated} />
            </Panel>
          </Col>
          <Col md={4} xl={4} lg={4} xs={12}>
            <Panel title="Completed Word Searches">
              <TotalCount total={information.wordSearchCompleted} />
            </Panel>
          </Col>
        </Row>
      </div>
    );
  };

  const renderBadges = badgesResponse => {
    if (loadingBadges) {
      return renderLoadingComponent('Loading Badges');
    }
    if (!badgesResponse) {
      return null;
    }
    if (badgesResponse.type !== 'API_SUCCESS') {
      return (
        <div>
          <ErrorAlert
            canRetry={false}
            message="There was an error loading badges data"
          />
        </div>
      );
    }

    const badgesPayload = badgesResponse.payload;

    return (
      <div>
        <SubHeading subHeading="Top Core Badges" />
        <Row>{renderTopBadges(badgesPayload)}</Row>
        <SubHeading subHeading="Top Limited Badges" />
        <Row>{renderLimitedEditionBadges(badgesPayload)}</Row>
      </div>
    );
  };

  const renderPacks = packsResponse => {
    if (loadingBadges) {
      return renderLoadingComponent('Loading Trivia Packs');
    }
    if (!packsResponse) {
      return null;
    }
    if (packsResponse.type !== 'API_SUCCESS') {
      return (
        <div>
          <ErrorAlert
            canRetry={false}
            message="There was an error loading packs data"
          />
        </div>
      );
    }

    const packs = packsResponse.payload;

    return (
      <div>
        <SubHeading subHeading="Top Trivia Packs" />
        <Row>{renderTopTriviaPacks(packs)}</Row>
        {renderTopPremierePlaysPacks(packs)}
      </div>
    );
  };

  const renderGoogleAnalytics = analyticsResponse => {
    if (loadingAnalytics) {
      return renderLoadingComponent('Loading Google Analytics');
    }
    if (!analyticsResponse) {
      return null;
    }
    if (analyticsResponse.type !== 'API_SUCCESS') {
      return (
        <div>
          <ErrorAlert
            canRetry={false}
            message="There was an error loading Google Analyitcs"
          />
        </div>
      );
    }

    const analyticsPayload = analyticsResponse.payload;

    return (
      <div>
        <SubHeading subHeading="Google Analytics" />
        <Row>
          <Col md={6} xl={6} lg={6} xs={12}>
            <Panel title="Page Views">
              <TotalCount total={analyticsPayload.pageViews} />
            </Panel>
          </Col>
          <Col md={6} xl={6} lg={6} xs={12}>
            <Panel title="Average Time on Site">
              <TotalCount total={analyticsPayload.averageTime} />
            </Panel>
          </Col>
          <Col md={4} xl={4} lg={4} xs={12}>
            <Panel title="Sessions">
              <TotalCount total={analyticsPayload.sessions} />
            </Panel>
          </Col>
          <Col md={4} xl={4} lg={4} xs={12}>
            <Panel title="Unique Visitors">
              <TotalCount total={analyticsPayload.uniqueVisitors} />
            </Panel>
          </Col>
          <Col md={4} xl={4} lg={4} xs={12}>
            <Panel title="Engaged Time Per Session">
              <TotalCount total={analyticsPayload.engagedPerUser} />
            </Panel>
          </Col>
          {/* <Col md={4} xl={4} lg={4} xs={12}>
            <Panel title="Banner Sessions">
              <TotalCount total={analyticsPayload.bannerSessions} />
            </Panel>
          </Col> */}
          <Col md={4} xl={4} lg={4} xs={12}>
            <Panel title="Storyteller Swipeups">
              <TotalCount total={analyticsPayload.storytellerSwipeups} />
            </Panel>
          </Col>
          {/* <Col md={4} xl={4} lg={4} xs={12}>
            <Panel title="Hero Sessions">
              <TotalCount total={analyticsPayload.heroSessions} />
            </Panel>
          </Col> */}
        </Row>
      </div>
    );
  };

  return (
    <Content title="Dashboard" className="dashboard">
      <div>
        <SubHeading subHeading="Filters" />
        <Row className="mb-4">
          <Col md={4} xl={4} lg={4} xs={12}>
            <FormGroup>
              <Label for="startDate">Start Date</Label>
              <Input
                id="startDate"
                name="startDate"
                type="date"
                onChange={e => setStartDate(e.target.value)}
              />
            </FormGroup>
          </Col>
          <Col md={4} xl={4} lg={4} xs={12}>
            <FormGroup>
              <Label for="endDate">End Date</Label>
              <Input
                id="endDate"
                name="endDate"
                type="date"
                onChange={e => setEndDate(e.target.value)}
              />
            </FormGroup>
          </Col>
          <Col md={12} xl={12} lg={12} xs={12}>
            <Button
              buttonStyle="solid"
              color="primary"
              disabled={!startDate || !endDate}
              onClick={() => {
                setLoadingOverview(true);
                setLoadingAdditionalInformation(true);
                setLoadingPackInformationSection(true);
                setLoadingPackInformation(true);
                setLoadingCompletedPackInformation(true);
                setLoadingQuestionPackInformation(true);
                setLoadingPolls(true);
                setLoadingBadges(true);
                setLoadingAnalytics(true);

                props.getPackInformation(startDate, endDate).then(res => {
                  setPackInformation(res);
                  setLoadingPackInformation(false);
                });
                props
                  .getCompletedPackInformation(startDate, endDate)
                  .then(res => {
                    setCompletedPackInformation(res);
                    setLoadingCompletedPackInformation(false);
                  });
                props
                  .getQuestionPackInformation(startDate, endDate)
                  .then(res => {
                    setQuestionPackInformation(res);
                    setLoadingQuestionPackInformation(false);
                  });
                props.getAdditionalInformation(startDate, endDate).then(res => {
                  setAdditionalInformation(res);
                  setLoadingAdditionalInformation(false);
                });
                props.getOverview(startDate, endDate).then(res => {
                  setOverview(res);
                  setLoadingOverview(false);
                });
                props.getPolls(startDate, endDate).then(res => {
                  setPolls(res);
                  setLoadingPolls(false);
                });
                props.getBadges(startDate, endDate).then(res => {
                  setBadges(res);
                  setLoadingBadges(false);
                });
                props.getGoogleAnalytics(startDate, endDate).then(res => {
                  setAnalytics(res);
                  setLoadingAnalytics(false);
                });
              }}
            >
              Generate Report
            </Button>
          </Col>
        </Row>
      </div>
      {renderOverview(overview)}
      {renderPackInformation(
        packInformation,
        questionPackInformation,
        completedPackInformation
      )}
      {renderPolls(polls)}
      {renderAdditionalInformation(additionalInformation)}
      {renderBadges(badges)}
      {renderGoogleAnalytics(analytics)}
      {renderPacks(badges)}
    </Content>
  );
};

export default enhance(Dashboard);
