import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusCircle } from '@fortawesome/pro-solid-svg-icons';
import Button from 'components/Button';
import { visibility } from 'shared/enhancers';

const SingleFieldItems = ({ Component, name, values, remove, ...props }) => (
  <React.Fragment>
    {values.map((_, index) => (
      <div className="single-field" key={`${name + index}`}>
        <Component {...props} index={index} name={`${name}[${index}]`} />
        <Button
          aria-label="Remove"
          className="btn-light icon fieldset-right-icon rounded-pill"
          buttonStyle="link"
          onClick={() => {
            remove(index);
            document.activeElement.blur();
          }}
          outline
          color="danger"
          title="Remove"
          icon={
            <FontAwesomeIcon icon={faMinusCircle} className="text-danger" />
          }
        />
      </div>
    ))}
  </React.Fragment>
);
SingleFieldItems.propTypes = {
  Component: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  values: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  remove: PropTypes.func.isRequired,
};

export default visibility(SingleFieldItems);
