import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/pro-regular-svg-icons';
import PropTypes from 'prop-types';
import classNames from 'classnames';

/**
 * A description of the component and when it should be used.
 */

class CheckBoxField extends React.Component {
  componentDidMount() {
    // Not compatible with formik
    // this.props.onChange(this.props.defaultChecked);
  }

  render() {
    const {
      disabled,
      className,
      name,
      value,
      onChange,
      label,
      color,
    } = this.props;
    const CheckboxClass = classNames({
      'checkbox-btn': true,
      disabled,
    });
    return (
      <label
        className={`${CheckboxClass} ${
          className ? `checkbox-btn--${className}` : ''
        }`}
        htmlFor={name}
      >
        <input
          className="checkbox-btn__checkbox"
          type="checkbox"
          id={name}
          name={name}
          onChange={onChange}
          checked={value}
          disabled={disabled}
        />
        <span
          className="checkbox-btn__checkbox-custom"
          style={color ? { background: color, borderColor: color } : {}}
        >
          <FontAwesomeIcon icon={faCheck} />
        </span>
        {className === 'button' ? (
          <span className="checkbox-btn__label-svg">
            <FontAwesomeIcon
              icon={faCheck}
              className="checkbox-btn__label-check"
            />
            <FontAwesomeIcon
              icon={faTimes}
              className="checkbox-btn__label-uncheck"
            />
          </span>
        ) : (
          ''
        )}
        <span className="checkbox-btn__label">{label}</span>
      </label>
    );
  }
}

CheckBoxField.propTypes = {
  onChange: PropTypes.func,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  label: PropTypes.string,
  // defaultChecked: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  color: PropTypes.string,
};

CheckBoxField.defaultProps = {
  label: '',
  onChange: null,
  // defaultChecked: false,
  value: null,
  disabled: false,
  className: '',
  color: '',
};

const renderCheckBoxField = props => {
  const {
    input,
    label,
    defaultChecked,
    disabled,
    className,
    color,
    onChange,
    value,
  } = props;
  return (
    <CheckBoxField
      {...input}
      label={label}
      defaultChecked={defaultChecked}
      disabled={disabled}
      className={className}
      color={color}
      onChange={onChange}
      value={value}
    />
  );
};

renderCheckBoxField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    name: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  }).isRequired,
  label: PropTypes.string,
  defaultChecked: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  color: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

renderCheckBoxField.defaultProps = {
  label: '',
  defaultChecked: false,
  disabled: false,
  className: '',
  color: '',
  onChange: () => {},
  value: null,
};

export default renderCheckBoxField;
