import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './ChartTooltip.scss';

// https://stackoverflow.com/questions/21646738/convert-hex-to-rgba#answer-51564734
const convertHex = (hex, alpha = 1) => {
  const [r, g, b] = hex.match(/\w\w/g).map(x => parseInt(x, 16));
  return `rgba(${r},${g},${b},${alpha})`;
};

/** A custom tooltip for area/bar/line charts */

const ChartTooltip = ({ formatter, label, payload }) =>
  payload && ( // make sure payload exists before rendering anything to prevent errors
    <div
      className={classnames('chart-tooltip', {
        multiple: payload.length > 1,
      })}
      style={
        payload[0] && {
          borderColor: convertHex(payload[0].stroke || payload[0].fill, 0.1),
        }
      }
    >
      <div
        className="header"
        style={
          payload[0] && {
            backgroundColor: convertHex(
              payload[0].stroke || payload[0].fill,
              0.1
            ),
          }
        }
      >
        <h3>{formatter(label)}</h3>
      </div>
      {payload.length === 1 ? (
        <div className="info">
          <h4 style={{ color: payload[0].stroke || payload[0].fill }}>
            {new Intl.NumberFormat().format(payload[0].value)}
          </h4>
          <small>{payload[0].name}</small>
        </div>
      ) : (
        payload.length >= 2 &&
        payload.map(
          (x, i) =>
            payload[i] && (
              // eslint-disable-next-line react/no-array-index-key
              <div className="info" key={i}>
                <h4 style={{ color: payload[i].stroke || payload[i].fill }}>
                  {new Intl.NumberFormat().format(payload[i].value)}
                </h4>
                <small>{payload[i].name}</small>
              </div>
            )
        )
      )}
    </div>
  );

ChartTooltip.propTypes = {
  /** Function to return more human-readable data. */
  formatter: PropTypes.func,
  /** X-axis label */
  label: PropTypes.string,
  /** Payload provided by recharts, with prop checking for the values we use */
  payload: PropTypes.arrayOf(
    PropTypes.shape({
      stroke: PropTypes.string,
      fill: PropTypes.string,
      value: PropTypes.number,
      name: PropTypes.string,
    })
  ),
};

ChartTooltip.defaultProps = {
  formatter: x => x,
  label: '',
  payload: null,
};

export default ChartTooltip;
