import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import SidebarContent from './SidebarContent';
import { changeMobileSidebarVisibility } from './sidebarActions';
import './Sidebar.scss';

const Sidebar = ({ sidebar, menu }) => {
  const sidebarClass = classNames({
    sidebar: true,
    'sidebar--show': sidebar.show,
    'sidebar--collapse': sidebar.collapse,
  });

  return (
    <div className={sidebarClass}>
      <button
        type="button"
        className="sidebar__back"
        onClick={changeMobileSidebarVisibility}
      />
      <div className="sidebar__wrapper sidebar__wrapper--desktop">
        <SidebarContent menu={menu} />
      </div>
      <div className="sidebar__wrapper sidebar__wrapper--mobile">
        <SidebarContent onClick={changeMobileSidebarVisibility} menu={menu} />
      </div>
    </div>
  );
};

Sidebar.propTypes = {
  sidebar: PropTypes.shape({
    show: PropTypes.bool,
    collapse: PropTypes.bool,
  }).isRequired,
  menu: PropTypes.shape().isRequired,
};

export default Sidebar;
