import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { startCase } from 'lodash';
import { processValidate, renderField } from 'infrastructure/form';

/** Generic input element with some restrictions to ensure better usage */

const TextField = props => {
  const { id, name, label, type, validate } = props;

  return (
    <Field
      {...props}
      component={renderField}
      id={id || name}
      maxLength={type === 'time' && 5}
      name={name}
      type={type}
      pattern={type === 'time' && '(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]'}
      validate={processValidate(label || startCase(name), validate, props)}
    />
  );
};

TextField.propTypes = {
  label: PropTypes.string,
  /** Input name & ID */
  name: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Input type, others are supported in HTML but there should be separate components for those */
  type: PropTypes.oneOf([
    'email',
    'number',
    'password',
    'search',
    'tel',
    'text',
    'url',
    'time',
  ]),
  /** Validation array */
  validate: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.shape({})])
    ),
    PropTypes.bool,
  ]),
};

TextField.defaultProps = {
  label: '',
  name: '',
  id: '',
  type: 'text',
  validate: [],
};

export default TextField;
