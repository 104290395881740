import React from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'reactstrap';
import Breadcrumbs from '../Breadcrumbs';
import Panel from '../Panel';
import Title from '../Title';
import SubHeading from '../SubHeading';

import './Content.scss';

const Content = ({ children, className, topRightContent, ...props }) => (
  <Container className={`content-container ${className}`}>
    <Row className="page-header-row">
      <Col xs="auto" className="page-header">
        <Breadcrumbs {...props} />
        <Title {...props} />
        <SubHeading {...props} />
      </Col>
      <Col xs="auto" className="top-right-content">
        {topRightContent}
      </Col>
    </Row>
    <Row>
      <Col>
        <Panel className="main-content-card">{children}</Panel>
      </Col>
    </Row>
  </Container>
);

Content.propTypes = {
  /** App contents */
  children: PropTypes.node,
  /** Container classes */
  className: PropTypes.string,
  /** An array of parent pages for Breadcrumbs */
  path: PropTypes.arrayOf(
    PropTypes.shape({
      displayName: PropTypes.string,
      url: PropTypes.string,
    })
  ),
  /** Page subtitle */
  subHeading: PropTypes.string,
  /** Title of the page */
  title: PropTypes.string,
  /** Used for buttons etc in the top right of the page */
  topRightContent: PropTypes.node,
};

Content.defaultProps = {
  children: null,
  className: null,
  path: [{ url: '/', displayName: 'Home' }],
  subHeading: null,
  title: null,
  topRightContent: null,
};

export default Content;
