import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import { faSync } from '@fortawesome/pro-regular-svg-icons';
import { Button } from 'components';
import './ErrorMessage.scss';

const ErrorAlert = ({ canRetry, message }) => (
  <div className="error-message-page">
    <Alert color="danger" className="alert--bordered" isOpen>
      <div className="alert-icon">
        <FontAwesomeIcon icon={faExclamationTriangle} />
      </div>
      <div className="alert-content">
        <h3>{message}</h3>
      </div>
      {canRetry && (
        <Button
          className="btn-retry"
          color="dark"
          onClick={() => window.location.reload()}
          size="sm"
        >
          <FontAwesomeIcon icon={faSync} /> Try again
        </Button>
      )}
      <a href="/" className="go-home">
        Go home
      </a>
    </Alert>
  </div>
);

ErrorAlert.propTypes = {
  canRetry: PropTypes.bool,
  message: PropTypes.string,
};

ErrorAlert.defaultProps = {
  canRetry: true,
  message: 'Oops! Something went wrong.',
};

export default ErrorAlert;
