import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import renderDropZoneField from './DropZoneField';
import './DropZone.scss';

/** A component for uploading file(s) */

const wrapValue = value => {
  if (!value) return value;

  if (!Array.isArray(value)) {
    return [value];
  }

  return value;
};

const DropZone = props => {
  const {
    accept,
    className,
    customRemoveFile,
    dropZoneShape,
    emptyMessage,
    fieldProps,
    maxSize,
    multiple,
    name,
    onChange,
    onDropRejected,
    previewShape,
  } = props;
  return (
    <Field
      name={name}
      render={({ form, field }) =>
        renderDropZoneField({
          accept,
          className,
          dropZoneShape,
          emptyMessage,
          fieldProps,
          input: {
            ...field,
            value: wrapValue(field.value, multiple),
            onChange: files => {
              if (multiple || !files || !Array.isArray(files)) {
                // form.setFieldValue(name, files);
                onChange(files);
                return;
              }
              const [file] = files;
              form.setFieldValue(name, file);
              onChange(file);
            },
          },
          maxSize,
          multiple,
          customRemoveFile,
          onDropRejected,
          previewShape,
        })
      }
    />
  );
};

DropZone.propTypes = {
  /** Array of accepted mime types */
  accept: PropTypes.arrayOf(PropTypes.string),
  /** Custom style class */
  className: PropTypes.string,
  /** For implying the shape an uploaded asset will be displayed in */
  customRemoveFile: PropTypes.func,
  /** Function for handling what happens when files are rejected */
  dropZoneShape: PropTypes.oneOf([
    null,
    'circle',
    'square',
    'portrait',
    'landscape',
  ]),
  /** Empty message */
  emptyMessage: PropTypes.node,
  /** Props to be passed directly to Dropzone field, including events for validation */
  fieldProps: PropTypes.shape({}),
  /** Maximum individual file size in bytes */
  maxSize: PropTypes.number,
  /** Can upload more than one file */
  multiple: PropTypes.bool,
  /** Input name & ID */
  name: PropTypes.string,
  /** onChange function */
  onChange: PropTypes.func,
  /** Handle when the user tries to upload the wrong file type */
  onDropRejected: PropTypes.func,
  /** For implying the shape an uploaded asset will be displayed in */
  previewShape: PropTypes.oneOf([
    null,
    'circle',
    'square',
    'portrait',
    'landscape',
  ]),
};

DropZone.defaultProps = {
  accept: [
    'image/jpg',
    'image/jpeg',
    'image/png',
    'video/mp4',
    'video/quicktime',
  ],
  className: '',
  customRemoveFile: null,
  dropZoneShape: null,
  emptyMessage: 'Drop a file here or click to upload',
  fieldProps: {
    accept: ['image/jpg', 'image/jpeg', 'image/png', 'video/mp4'],
  },
  maxSize: 64000000,
  multiple: false,
  name: '',
  onChange: () => {},
  onDropRejected: null,
  previewShape: null,
};

export default DropZone;
