import React from 'react';
import { BreadcrumbItem } from 'reactstrap';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import './Breadcrumb.scss';

const Breadcrumb = props => {
  const { title, to } = props;
  return (
    <BreadcrumbItem className="breadcrumb-item">
      {to ? <Link to={to}>{title}</Link> : title}
    </BreadcrumbItem>
  );
};

Breadcrumb.propTypes = {
  title: PropTypes.string,
  to: PropTypes.string,
};

Breadcrumb.defaultProps = {
  title: null,
  to: null,
};

export default Breadcrumb;
