import React from 'react';
import { Breadcrumb as BreadcrumbContainer } from 'reactstrap';
import PropTypes from 'prop-types';

import Breadcrumb from './Breadcrumb';

/** Page context breadcrumbs */

const Breadcrumbs = props => {
  let { path } = props;

  if (path && !path.length) {
    path = [{ displayName: 'Home', url: '/' }];
  }

  return (
    <BreadcrumbContainer>
      {path
        .filter(x => !x.isCurrent)
        .map(item => (
          <Breadcrumb key={item.url} title={item.displayName} to={item.url} />
        ))}
    </BreadcrumbContainer>
  );
};

Breadcrumbs.propTypes = {
  /** An array of parent pages */
  path: PropTypes.arrayOf(
    PropTypes.shape({
      /** Eg. "Home" or "Assets" */
      displayName: PropTypes.string,
      /** Link to the page (can be absolute or relative) */
      url: PropTypes.string,
    })
  ),
};

Breadcrumbs.defaultProps = {
  path: [{ displayName: 'Home', url: '/' }],
};

export default Breadcrumbs;
