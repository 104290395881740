import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';

import { Secured } from 'infrastructure/security';
import { ErrorBoundary, ErrorPage, NotFound } from 'infrastructure/error';
import { withCurrentUser } from 'infrastructure/currentUser';

import { compose } from 'recompose';

import Layout from 'containers/Layout';
import LogIn, { ForgotPassword, ResetPassword } from 'slices/Account/Loadable';
import MainWrapper from 'containers/App/MainWrapper';
import Dashboard from 'containers/Dashboard/index';
import Movie from 'slices/Movie/Loadable';

const enhance = compose(withCurrentUser, withRouter);

const wrappedRoutes = enhance(props => {
  const { location } = props;
  const background = location && location.state && location.state.background;

  return (
    <Secured>
      <React.Fragment>
        <Layout />
        <div className="container__wrap">
          <ErrorBoundary>
            <Switch location={background || location}>
              <Route path="/movies" component={Movie} />
              <Route path="/dashboard" component={Dashboard} />
              <Route path="*">
                <Redirect to="/dashboard" />
              </Route>
            </Switch>
          </ErrorBoundary>
        </div>
      </React.Fragment>
    </Secured>
  );
});

wrappedRoutes.propTypes = {
  location: PropTypes.shape({}),
};

wrappedRoutes.defaultProps = {
  location: {},
};

const AppRouter = props => {
  const { className } = props;
  return (
    <MainWrapper className={className}>
      <main>
        <Switch>
          <Route exact path="/login" component={LogIn} />
          <Route exact path="/forgot-password" component={ForgotPassword} />
          <Route path="/reset-password/" component={ResetPassword} />
          <Route path="/error" component={ErrorPage} />
          <Route path="/500" component={ErrorPage} />
          <Route path="/404" component={NotFound} />
          <Route path="/" component={wrappedRoutes} />
        </Switch>
      </main>
    </MainWrapper>
  );
};

wrappedRoutes.propTypes = {
  /** Container classes */
  className: PropTypes.string,
};

wrappedRoutes.defaultProps = {
  className: '',
};

export default AppRouter;
