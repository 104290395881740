import React from 'react';
import {
  Col,
  Row,
  Pagination,
  PaginationItem,
  PaginationLink,
} from 'reactstrap';
import { Breadcrumbs, Collapse } from 'components';
import DefinitionList from 'components/DefinitionList';
import Button from 'components/Button';
import Tags from 'components/Tags';
import PropTypes from 'prop-types';
import './AssetDetail.scss';

/** For use in a modal, to show a large view of an asset and all the info. */

const AssetDetail = props => {
  const {
    asset,
    breadcrumbs,
    contentTerms,
    fileInfoTerms,
    tags,
    similarAssets,
    title,
  } = props;
  return (
    <Row className="asset-detail-view" noGutters>
      <Col xs={12} md={7} lg={8} xl={9} className="asset-container">
        {asset}
      </Col>
      <Col className="asset-info">
        <div className="info-container light-scrollbar">
          <div className="info-header info-section">
            {breadcrumbs && <Breadcrumbs path={breadcrumbs} />}
            <h2>{title}</h2>
            <Row className="asset-actions">
              <Col>
                <Button color="primary" className="rounded-pill" block outline>
                  <span className="lnr lnr-download2" /> Download
                </Button>
              </Col>
              <Col>
                <Button color="primary" className="rounded-pill" block>
                  <span className="lnr lnr-checkmark-circle" /> Collect
                </Button>
              </Col>
            </Row>
          </div>
          {similarAssets && (
            <Collapse title="Similar Assets" className="minimal info-section">
              {similarAssets}
            </Collapse>
          )}
          {tags && (
            <Collapse title="Keywords" className="minimal info-section">
              <Tags tags={tags} />
            </Collapse>
          )}
          {contentTerms && (
            <Collapse title="Content" className="minimal info-section">
              <DefinitionList terms={contentTerms} />
            </Collapse>
          )}
          {fileInfoTerms && (
            <Collapse title="File Info" className="minimal info-section">
              <DefinitionList terms={fileInfoTerms} tableLayout />
            </Collapse>
          )}
        </div>
        <Pagination
          aria-label="Page navigation example"
          className="asset-detail-pagination"
        >
          <PaginationItem>
            <PaginationLink previous>
              <span className="lnr lnr-arrow-left" />
            </PaginationLink>
          </PaginationItem>
          <PaginationItem className="current-position">1 of 4</PaginationItem>
          <PaginationItem>
            <PaginationLink next>
              <span className="lnr lnr-arrow-right" />
            </PaginationLink>
          </PaginationItem>
        </Pagination>
      </Col>
    </Row>
  );
};

AssetDetail.propTypes = {
  /** Breadcrumbs values */
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      /** Eg. "Home" or "Assets" */
      displayName: PropTypes.string,
      /** Link to the page (can be absolute or relative) */
      url: PropTypes.string,
    })
  ),
  /** Definition list, could be used for describing the content with ML/sentiment analysis from Azure */
  contentTerms: PropTypes.arrayOf(PropTypes.shape({})),
  /** File details, size, format etc. */
  fileInfoTerms: PropTypes.arrayOf(PropTypes.shape({})),
  /** An array of tags */
  tags: PropTypes.arrayOf(PropTypes.string),
  /** AssetCards to link to similar assets */
  similarAssets: PropTypes.node,
  /** Media component */
  asset: PropTypes.node,
  /** Asset name */
  title: PropTypes.string,
};

AssetDetail.defaultProps = {
  breadcrumbs: [],
  contentTerms: [],
  fileInfoTerms: [],
  tags: [],
  similarAssets: null,
  asset: null,
  title: '',
};

export default AssetDetail;
