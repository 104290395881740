import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  AreaChart as Rechart,
  Area,
  CartesianAxis,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import ChartTooltip from 'components/Charts/ChartTooltip';
import YAxisTick from 'components/Charts/YAxisTick';
import Measure from 'react-measure';
import { UIMessage, Loading } from 'components';
import variables from 'scss/1-settings/colors.scss';
import './AreaChart.scss';

/** A wrapper for Recharts AreaChart. */

const AreaChart = props => {
  const {
    aspect,
    aspectFunc,
    autoFormatYTicks,
    colorArray,
    data,
    dots,
    emptyState,
    height,
    hideXAxis,
    hideYAxis,
    legend,
    loading,
    mirrorY,
    percentageChart,
    strokeWidth,
    syncId,
    tooltip,
    tooltipFormatter,
    xAxisFormatter,
    xGrid,
    xInterval,
    xPaddingEnd,
    xPaddingStart,
    yAxisFormatter,
    yGrid,
    yInterval,
    yUnit,
  } = props;

  const [dimentions, setDimentions] = useState({
    dimensions: {
      width: -1,
      height: -1,
    },
  });
  const { width } = dimentions;

  const formattedData = (data && data.map(x => x.values)) || null;

  const kiloFunc = value => `${parseFloat(value) / Number('1e3')}k`;
  const megaFunc = value => `${parseFloat(value) / Number('1e6')}M`;

  // averageValues returns an array with the averages of the values at each point along the X-Axis
  const averageValues =
    formattedData &&
    formattedData.length &&
    formattedData.map(valuesObj => {
      const totals = Object.values(valuesObj).reduce(
        (accumulator, value) =>
          accumulator + (typeof value === 'number' ? value : 0),
        0
      );
      const averages = totals / (Object.values(valuesObj).length - 1);
      return averages;
    });

  const averageValue =
    averageValues &&
    averageValues.length &&
    averageValues.reduce((a, b) => a + b) / averageValues.length;

  const labels =
    data && data[0]
      ? Object.keys(data[0].values).filter(
          property => property !== 'name' // Remove key for name
        )
      : [];

  const color = i => {
    if (colorArray) {
      return colorArray[i];
    }
    return variables[
      `chart-color-${(i + 1)
        .toString()
        .split('')
        .pop()}`
    ]; // Only take the last digit so it matches a color variable
  };

  return (
    <Measure bounds onResize={contentRect => setDimentions(contentRect.bounds)}>
      {({ measureRef }) => (
        <div
          className="area-chart"
          ref={measureRef}
          style={{ minHeight: height }}
        >
          <Loading visible={loading} maskContainer />
          {formattedData && formattedData.length ? (
            <ResponsiveContainer
              aspect={!height ? aspectFunc(aspect, width) : undefined}
              className="chart responsive"
              height={height}
            >
              <Rechart
                data={formattedData}
                margin={{ top: 14, right: 0, bottom: 0, left: 0 }}
                stackOffset="expand"
                syncId={syncId}
              >
                <defs>
                  {labels.map((label, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <linearGradient
                      key={`${label + index}`}
                      id={`gradient-${color(index)}`}
                      x1="0"
                      y1="0"
                      x2="0"
                      y2="1"
                    >
                      <stop
                        offset="5%"
                        stopColor={color(index)}
                        stopOpacity={percentageChart ? 1 : 0.6}
                      />
                      <stop
                        offset="100%"
                        stopColor={color(index)}
                        stopOpacity={percentageChart ? 1 : 0.2}
                      />
                    </linearGradient>
                  ))}
                </defs>
                {tooltip && (
                  <Tooltip
                    content={<ChartTooltip />}
                    formatter={label => tooltipFormatter(label)}
                  />
                )}
                <CartesianGrid
                  strokeDasharray="4 4"
                  horizontal={xGrid}
                  vertical={yGrid}
                />
                <CartesianAxis />
                {labels.map((label, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Area
                    connectNulls
                    dataKey={label}
                    isAnimationActive={false}
                    key={label}
                    fill={`url(#gradient-${color(index)})`}
                    fillOpacity={1}
                    stackId={percentageChart ? '1' : null}
                    type="monotone"
                    stroke={color(index)}
                    strokeWidth={percentageChart ? 0 : strokeWidth}
                    dot={
                      !percentageChart &&
                      dots && {
                        fill: 'white',
                        fillOpacity: 1,
                        r: 6,
                        stroke: color(index),
                        strokeWidth: 2,
                      }
                    }
                    activeDot={
                      !percentageChart && {
                        fill: 'white',
                        r: 6,
                        stroke: color(index),
                        strokeWidth: 2,
                      }
                    }
                  />
                ))}
                <XAxis
                  allowDecimals={false}
                  dataKey="name"
                  hide={hideXAxis}
                  interval={xInterval}
                  minTickGap={4}
                  padding={{ left: xPaddingStart, right: xPaddingEnd }}
                  tickFormatter={xAxisFormatter}
                />
                <YAxis
                  allowDecimals={false}
                  axisLine={false}
                  hide={hideYAxis}
                  minTickGap={4}
                  mirror={mirrorY}
                  interval={yInterval}
                  tick={
                    <YAxisTick
                      formatter={
                        percentageChart
                          ? (decimal, fixed = 0) =>
                              `${(decimal * 100).toFixed(fixed)}%`
                          : yAxisFormatter ||
                            (autoFormatYTicks &&
                              averageValue > 2000000 &&
                              megaFunc) ||
                            (autoFormatYTicks &&
                              averageValue > 2000 &&
                              kiloFunc) ||
                            null
                      }
                      unit={yUnit}
                    />
                  }
                  tickFormatter={(decimal, fixed = 0) =>
                    percentageChart && `${(decimal * 100).toFixed(fixed)}%`
                  }
                  tickLine={false}
                  tickSize={0}
                  unit={yUnit}
                />
                {legend && <Legend className="chart-legend" />}
              </Rechart>
            </ResponsiveContainer>
          ) : (
            !loading && emptyState
          )}
        </div>
      )}
    </Measure>
  );
};

AreaChart.propTypes = {
  /** Aspect ratio, define the horizontal width as a multiple of the height (eg. 0.5 or 3) */
  aspect: PropTypes.number,
  /** A function to responsively calculate the aspect ratio. Defaults to doubling the height at < 500px. See the story. */
  aspectFunc: PropTypes.func,
  /** Automatically prefix units (eg. k or M) */
  autoFormatYTicks: PropTypes.bool,
  /** Array of colors for the lines */
  colorArray: PropTypes.arrayOf(PropTypes.string),
  /** An array of objects describing the data. Each object describes the data at a point on the X-axis. */
  data: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      values: PropTypes.shape({
        /** Map labels to numbers here */
        /** Name the X-axis */
        name: PropTypes.string,
      }),
    })
  ),
  /** Show dots (only if not formatted as a percentage chart) */
  dots: PropTypes.bool,
  /** Node or message to show when there's no data */
  emptyState: PropTypes.node,
  /** Height in px */
  height: PropTypes.number,
  /** Show legend */
  legend: PropTypes.bool,
  /** Show show loading spinner */
  loading: PropTypes.bool,
  /** Place Y-axis labels inside the graph */
  mirrorY: PropTypes.bool,
  /** Show X-axis labels */
  hideXAxis: PropTypes.bool,
  /** Show Y-axis labels */
  hideYAxis: PropTypes.bool,
  /** Show values as a percentage */
  percentageChart: PropTypes.bool,
  /** Stroke width for the area shape */
  strokeWidth: PropTypes.number,
  /** Optionally sync tooltips with other graphs by providing a shared ID */
  syncId: PropTypes.string,
  /** show tooltip with values on hover */
  tooltip: PropTypes.bool,
  /** Function to return more human-readable data. */
  tooltipFormatter: PropTypes.func,
  /** Function to return more human-readable data. */
  xAxisFormatter: PropTypes.func,
  /** Show horizontal lines */
  xGrid: PropTypes.bool,
  /** Set how to render the X Axis labels http://recharts.org/en-US/api/XAxis#interval */
  xInterval: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.oneOf(['preserveStart', 'preserveEnd', 'preserveStartEnd']),
  ]),
  /** Padding left, useful for making space for the scale */
  xPaddingStart: PropTypes.number,
  /** Padding right */
  xPaddingEnd: PropTypes.number,
  /** Function to return more human-readable data. */
  yAxisFormatter: PropTypes.func,
  /** Show vertical lines */
  yGrid: PropTypes.bool,
  /** Set how to render the Y Axis labels http://recharts.org/en-US/api/XAxis#interval */
  yInterval: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.oneOf(['preserveStart', 'preserveEnd', 'preserveStartEnd']),
  ]),
  /** Unit suffix for the Y Axis */
  yUnit: PropTypes.string,
};

AreaChart.defaultProps = {
  aspect: 3,
  aspectFunc: () => {},
  autoFormatYTicks: true,
  colorArray: null,
  data: null,
  dots: false,
  emptyState: <UIMessage message="No data to display" />,
  height: null,
  hideXAxis: false,
  hideYAxis: false,
  legend: false,
  loading: false,
  mirrorY: true,
  percentageChart: false,
  strokeWidth: 2,
  syncId: '',
  tooltip: true,
  tooltipFormatter: x => x,
  xAxisFormatter: null,
  xGrid: false,
  xInterval: 'preserveStartEnd',
  xPaddingEnd: 0,
  xPaddingStart: 0,
  yAxisFormatter: null,
  yGrid: false,
  yInterval: 0,
  yUnit: '',
};

export default AreaChart;
