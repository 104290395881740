import { createApiReducer } from 'infrastructure/api';

export const initialState = {};

const update = (state, payload) => ({
  ...payload,
});

const handlers = {
  'app/dashboard/GET_STATS': update,
  'app/dashboard/GET_OVERVIEW': update,
  'app/dashboard/GET_PACK_INFORMATION': update,
  'app/dashboard/GET_POLLS': update,
  'app/dashboard/GET_ADDITIONAL_INFORMATION': update,
  'app/dashboard/GET_BADGES': update,
  'app/dashboard/GET_GOOGLE_ANALYTICS': update,
  'app/dashboard/GET_QUESTION_PACK_INFORMATION': update,
  'app/dashboard/GET_COMPLETED_PACK_INFORMATION': update,
};

const apiReducer = createApiReducer(handlers, initialState);

export default apiReducer;
