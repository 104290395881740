const apiManager = {
  setApi(api) {
    this.api = api;
  },
  getApi() {
    return this.api;
  },
};

export default apiManager;
