import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { loading } from 'shared/enhancers';
import { Loading } from 'components';

import SidebarLink from './SidebarLink';
import SidebarCategory from './SidebarCategory';

const enhance = loading(
  x => !x.menu || !x.menu.items.length,
  () => <Loading visible />
);

class SidebarContent extends Component {
  static propTypes = {
    onClick: PropTypes.func,
    menu: PropTypes.shape(),
  };

  static defaultProps = {
    onClick: null,
    menu: {
      items: [],
    },
  };

  hideSidebar = () => {
    const { onClick } = this.props;
    if (onClick) onClick();
  };

  renderItems = items =>
    items.map((item, n) => {
      let LinkComponent = SidebarLink;
      if (!item.isLeaf) {
        LinkComponent = SidebarCategory;
      }
      return (
        <>
          {item.customData && item.customData.type === 'separator' ? (
            // eslint-disable-next-line react/no-array-index-key
            <hr key={n} />
          ) : (
            <LinkComponent
              title={item.displayName}
              route={item.url}
              className={item.cssClass}
              key={item.elementId}
              icon={item.icon}
              onClick={this.hideSidebar}
            >
              <ul>{this.renderItems(item.items)}</ul>
            </LinkComponent>
          )}
        </>
      );
    });

  render() {
    const { menu } = this.props;

    return (
      <div className="sidebar__content">
        <ul className="sidebar__block">{this.renderItems(menu.items)}</ul>
      </div>
    );
  }
}

export default enhance(SidebarContent);
