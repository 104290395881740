import React, { useState } from 'react';

import { Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { Button, Modal } from 'components';

const UnscheduleModal = ({
  title,
  onUnschedule,
  onCancel,
  isPublished,
  entityName,
}) => {
  const [isRemoving, setIsRemoving] = useState(false);
  const [published, setIsPublished] = useState(isPublished);

  return (
    <Modal
      hideButton
      show
      color="danger"
      confirmationModal
      onCancel={onCancel}
      size="sm"
      footer={
        <Row>
          <Col xs="auto">
            <Button
              color="dark"
              icon={<FontAwesomeIcon icon={faTimes} />}
              onClick={onCancel}
              outline
              disabled={isRemoving}
            >
              Cancel
            </Button>
          </Col>
          <Col xs="auto">
            <Button
              autofocus="true"
              color="danger"
              icon={<FontAwesomeIcon icon={faTrashAlt} />}
              onClick={() => {
                setIsPublished(isPublished);
                setIsRemoving(true);
                onUnschedule();
              }}
              loading={isRemoving}
            >
              Yes, Remove
            </Button>
          </Col>
        </Row>
      }
    >
      <h3>
        {published
          ? `${title} is live.
      This will immediately remove the ${entityName} from the app.`
          : `Are you sure you want to remove the schedule from ${title}?`}
      </h3>
    </Modal>
  );
};

export default UnscheduleModal;
