import React from 'react';
import classnames from 'classnames';
import {
  Modal as ModalContainer,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import { Button } from 'components';
import './Modal.scss';
import PropTypes from 'prop-types';

/** A wrapper for the reactstrap modal component ensuring more consistent styling */
class Modal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: props.show,
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen,
    }));
    document.activeElement.blur();
  }

  render() {
    const {
      buttonContents,
      buttonProps,
      children,
      className,
      color,
      footer,
      hideButton,
      onClosed,
      onCancel,
      size,
      title,
    } = this.props;

    const { isOpen } = this.state;

    return (
      <React.Fragment>
        {!hideButton && (
          <Button {...buttonProps} onClick={this.toggle}>
            {buttonContents}
          </Button>
        )}
        <ModalContainer
          className={classnames(className, color)}
          isOpen={isOpen}
          size={size}
          toggle={this.toggle}
          onClosed={onClosed}
        >
          <Button
            className="close-btn rounded-pill"
            color="light"
            onClick={() => {
              this.toggle();
              onCancel();
            }}
            type="button"
            icon={<span className="lnr lnr-cross" />}
          />
          {title && <ModalHeader>{title}</ModalHeader>}
          {children && <ModalBody>{children}</ModalBody>}
          {footer && <ModalFooter>{footer}</ModalFooter>}
        </ModalContainer>
      </React.Fragment>
    );
  }
}

Modal.propTypes = {
  /** Contents of the trigger button */
  buttonContents: PropTypes.string,
  /** Props object for the trigger button */
  buttonProps: PropTypes.shape({}),
  /** Modal contents */
  children: PropTypes.node,
  /** Modal container class */
  className: PropTypes.string,
  /** Border color for confirmation modal */
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'success',
    'danger',
    'warning',
    'info',
    'light',
    'dark',
    null,
  ]),
  /** Footer contents and actions */
  footer: PropTypes.node,
  /** Show trigger button? */
  hideButton: PropTypes.bool,
  /** onClosed function */
  onClosed: PropTypes.func,
  /** Modal size */
  size: PropTypes.oneOf([null, 'auto', 'sm', 'lg']),
  /** Show or hide the modal */
  show: PropTypes.bool,
  /** Header node, usually a `<h3 />` tag */
  title: PropTypes.node,
  onCancel: PropTypes.func,
};

Modal.defaultProps = {
  buttonContents: null,
  buttonProps: null,
  children: null,
  className: null,
  color: null,
  footer: null,
  hideButton: false,
  onClosed: () => {},
  size: null,
  show: false,
  title: null,
  onCancel: () => {},
};

export default Modal;
