export const getCookie = name =>
  document.cookie.split('; ').reduce((r, v) => {
    const parts = v.split('=');
    return parts[0] === name ? decodeURIComponent(parts[1]) : r;
  }, '');

export const setCookie = (name, value, minutes) => {
  const d = new Date();
  d.setTime(d.getTime() + minutes * 60 * 1000);
  const expires = `expires=${d.toUTCString()}`;
  document.cookie = `${name}=${value};${expires};path=/`;
};

export const deleteCookie = (name, value) => {
  const expires = `expires=Thu, 01 Jan 1970 00:00:01 GMT`;
  document.cookie = `${name}=${value};${expires};path=/`;
};
