import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faCheckCircle } from '@fortawesome/pro-solid-svg-icons';
import './CopyButton.scss';

/* A component to copy text to the clipboard */

const CopyButton = props => {
  const {
    buttonContents,
    buttonProps,
    onClick,
    onTimeout,
    successMessage,
    textToCopy,
    timeoutDuration,
  } = props;
  const [dynamicButtonContents, setButtonContents] = useState(buttonContents);
  const hiddenInput = useRef(null);

  const copyToClipboard = e => {
    hiddenInput.current.select();
    document.execCommand('copy');
    e.currentTarget.focus(); // De-select text and focus on the button
    setButtonContents(successMessage);
    if (onClick) onClick();
    // Reset button after 5 seconds
    setTimeout(() => {
      setButtonContents(buttonContents);
      document.activeElement.blur();
      if (onTimeout) onTimeout();
    }, timeoutDuration);
  };

  return (
    // Only render if copy command works
    document.queryCommandSupported('copy') && (
      <React.Fragment>
        <Button onClick={copyToClipboard} {...buttonProps}>
          {dynamicButtonContents}
        </Button>
        <form className="copy-value-container">
          <textarea ref={hiddenInput} value={textToCopy} readOnly />
        </form>
      </React.Fragment>
    )
  );
};

CopyButton.propTypes = {
  /** Contents of the button */
  buttonContents: PropTypes.node,
  /** Button props, like color, size etc. */
  buttonProps: PropTypes.shape({}),
  /** Click handler */
  onClick: PropTypes.func,
  /** Callback for performing a function when the success message is hidden, useful for tooltips */
  onTimeout: PropTypes.func,
  /** Success message as a node */
  successMessage: PropTypes.node,
  /** Text rop be copied to the clipboard */
  textToCopy: PropTypes.string.isRequired,
  /** Time in ms until success message is hidden */
  timeoutDuration: PropTypes.number,
};

CopyButton.defaultProps = {
  buttonContents: (
    <React.Fragment>
      <FontAwesomeIcon icon={faCopy} fixedWidth /> Copy to Clipboard
    </React.Fragment>
  ),
  buttonProps: {
    color: 'info',
  },
  onClick: null,
  onTimeout: null,
  successMessage: (
    <React.Fragment>
      <FontAwesomeIcon icon={faCheckCircle} fixedWidth /> Copied
    </React.Fragment>
  ),
  timeoutDuration: 5000,
};

export default CopyButton;
