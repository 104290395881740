import React from 'react';
import PropTypes from 'prop-types';
import { Field, getIn } from 'formik';
import { FormFeedback } from 'reactstrap';
import { startCase } from 'lodash';
import classnames from 'classnames';
import { processValidate, getValidity } from 'infrastructure/form';
import './DefaultSelect.scss';

const findValue = (options, field, defaultValue) => {
  if (!field || !field.value) {
    if (!defaultValue) return null;

    return options.find(x => x.value === defaultValue.value);
  }
  return options.find(x => x.value === field.value);
  // return options.find(x => field.value.indexOf(x.value) > -1);
};

const renderSelect = ({
  className,
  defaultValue,
  field,
  form,
  hideValidationStyling,
  onChange,
  name,
  options,
  placeholder,
  ...props
}) => {
  const { touched = {}, errors = {} } = form;
  const validity = getValidity(
    getIn(touched, field.name),
    getIn(errors, field.name)
  );

  return (
    <React.Fragment>
      <select
        {...props}
        className={classnames('form-control', className, {
          'is-invalid': validity.invalid && !hideValidationStyling,
          'is-valid': validity.valid && !hideValidationStyling,
        })}
        name={name}
        id={name}
        value={
          (typeof findValue(options, field, defaultValue) !== 'undefined' &&
            findValue(options, field, defaultValue).value) ||
          placeholder
        }
        onChange={e => {
          form.setFieldValue(field.name, e.target.value);
          form.setFieldTouched(field.name, true);
          onChange(e.target.value);
        }}
        onBlur={() => form.setFieldTouched(field.name, true)}
      >
        <option disabled value={placeholder || {}} hidden>
          {placeholder}
        </option>
        {options.map(option => (
          <option value={option.value} key={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      {!hideValidationStyling &&
        getIn(touched, field.name) &&
        getIn(errors, field.name) && (
          <FormFeedback
            className="validation form__form-group-error"
            tag="small"
          >
            {getIn(errors, field.name)}
          </FormFeedback>
        )}
    </React.Fragment>
  );
};

renderSelect.propTypes = {
  className: PropTypes.string,
  defaultValue: PropTypes.shape({}),
  field: PropTypes.shape(),
  form: PropTypes.shape(),
  onChange: PropTypes.func,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  placeholder: PropTypes.string,
  hideValidationStyling: PropTypes.bool,
};

renderSelect.defaultProps = {
  className: '',
  defaultValue: {},
  field: {},
  form: {},
  onChange: () => {},
  placeholder: null,
  hideValidationStyling: false,
};

const DefaultSelect = props => {
  const { validate, label, name } = props;
  return (
    <Field
      {...props}
      component={renderSelect}
      name={name}
      validate={processValidate(label || startCase(name), validate, props)}
    />
  );
};

DefaultSelect.propTypes = {
  /** Custom style class */
  className: PropTypes.string,
  /** Label for the input, used in validation */
  label: PropTypes.string,
  /** Input name & ID */
  name: PropTypes.string.isRequired,
  /** Validation array */
  validate: PropTypes.arrayOf(PropTypes.shape({})),
  /** onChange function handler */
  onChange: PropTypes.func,
};

DefaultSelect.defaultProps = {
  className: '',
  label: '',
  onChange: () => {},
  validate: [],
};

export default DefaultSelect;
