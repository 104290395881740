import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import classnames from 'classnames';

import './ToggleButton.scss';

class ToggleButtonField extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func,
    name: PropTypes.string,
    disabled: PropTypes.bool,
    value: PropTypes.bool,
  };

  static defaultProps = {
    onChange: null,
    name: '',
    disabled: false,
    value: false,
  };

  render() {
    const { name, disabled, value, onChange } = this.props;

    return (
      <div className={classnames('toggle-btn', { disabled })}>
        <input
          checked={value}
          className="toggle-input"
          disabled={disabled}
          name={name}
          onChange={onChange}
          type="checkbox"
        />
        <button
          className="toggle-input-btn"
          disabled={disabled}
          onClick={() => onChange(!value)}
          type="button"
        >
          Toggle
        </button>
      </div>
    );
  }
}

const renderToggleButtonField = ({ disabled, input }) => (
  <ToggleButtonField {...input} disabled={disabled} />
);

renderToggleButtonField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    name: PropTypes.string,
  }),
  disabled: PropTypes.bool,
};

renderToggleButtonField.defaultProps = {
  input: PropTypes.shape({
    onChange: null,
    name: '',
  }),
  disabled: false,
};

/** A custom toggle switch */

const ToggleButton = props => {
  const { name, value, onToggle, onChange } = props;

  return (
    <div>
      {name ? (
        <Field
          name={name}
          render={({ form, field, ...p }) =>
            renderToggleButtonField({
              ...props,
              input: {
                ...field,
                onChange: v => {
                  form.setFieldValue(name, v);
                  form.setFieldTouched(name, true);
                  onChange(v);
                  if (onToggle) {
                    onToggle(v);
                  }
                },
              },
              ...p,
            })
          }
        />
      ) : (
        <ToggleButtonField onChange={e => onChange(e)} value={value} />
      )}
    </div>
  );
};

ToggleButton.propTypes = {
  /** Input name & ID */
  name: PropTypes.string,
  /** value if not controlled by formik field */
  value: PropTypes.bool,
  /** onChange function handler */
  onChange: PropTypes.func,
  /** Optional function to trigger on toggle */
  onToggle: PropTypes.func,
};

ToggleButton.defaultProps = {
  name: '',
  value: false,
  onChange: () => {},
  onToggle: () => {},
};

export default ToggleButton;
