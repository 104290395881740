/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unused-expressions */
import PropTypes from 'prop-types';
import * as React from 'react';
import { Col, Label, Input, Row } from 'reactstrap';
import { Button } from 'components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import ToolbarActions from './ToolbarActions';
import FilteredTabs from './FilteredTabs/FilteredTabs';

import './TableToolbar.scss';

class MTableToolbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      result: 'No Data',
    };
  }

  componentDidMount() {
    const { storyStatusFilters, adStatusFilters } = this.props;
    this.storyPagingSummary(storyStatusFilters);
    this.adPagingSummary(adStatusFilters);
  }

  adPagingSummary = value => {
    const { updateAdStatusFilters, localization, state } = this.props;

    updateAdStatusFilters(value);
    const num = [];
    const { data } = state;
    data.forEach(item => {
      if (item.status === value) {
        num.push(item);
      }
    });

    if (value === '') {
      const allStatusTotal = state.data.length;
      const pageSize = +(state.pageSize || 5);
      const start = pageSize * +(state.page || 0);
      let end = start + pageSize;
      if (end > allStatusTotal) {
        end = allStatusTotal;
      }

      const overall =
        allStatusTotal > 0
          ? localization.pagingSummary
              .replace('{0}', start + 1)
              .replace('{1}', end)
              .replace('{2}', allStatusTotal)
          : '';

      this.setState({ result: overall });
    } else {
      const total = num.length;
      const pageSize = +(state.pageSize || 5);
      const start = pageSize * +(state.page || 0);
      let end = start + pageSize;
      if (end > total) {
        end = total;
      }

      const newResult =
        total > 0
          ? localization.pagingSummary
              .replace('{0}', start + 1)
              .replace('{1}', end)
              .replace('{2}', total)
          : localization.pagingSummary
              .replace('{0}', 0)
              .replace('{1}', 0)
              .replace('{2}', 0);

      this.setState({ result: newResult });
    }
  };

  storyPagingSummary = value => {
    const { updateStoryStatusFilters, localization, state } = this.props;

    updateStoryStatusFilters(value);
    const num = [];
    const { data } = state;
    data.forEach(item => {
      if (item.status === value) {
        num.push(item);
      }
    });

    if (value === '') {
      const allStatusTotal = state.data.length;
      const pageSize = +(state.pageSize || 5);
      const start = pageSize * +(state.page || 0);
      let end = start + pageSize;
      if (end > allStatusTotal) {
        end = allStatusTotal;
      }

      const overall =
        allStatusTotal > 0
          ? localization.pagingSummary
              .replace('{0}', start + 1)
              .replace('{1}', end)
              .replace('{2}', allStatusTotal)
          : '';

      this.setState({ result: overall });
    } else {
      const total = num.length;
      const pageSize = +(state.pageSize || 5);
      const start = pageSize * +(state.page || 0);
      let end = start + pageSize;
      if (end > total) {
        end = total;
      }

      const newResult =
        total > 0
          ? localization.pagingSummary
              .replace('{0}', start + 1)
              .replace('{1}', end)
              .replace('{2}', total)
          : localization.pagingSummary
              .replace('{0}', 0)
              .replace('{1}', 0)
              .replace('{2}', 0);

      this.setState({ result: newResult });
    }
  };

  stopSubmit = e => {
    e.preventDefault();
  };

  renderSearch() {
    const localization = {
      ...MTableToolbar.defaultProps.localization,
      ...this.props.localization,
    };

    const { search, searchText, onSearchChanged } = this.props;

    if (search) {
      return (
        <Col md={6} lg={4}>
          <form className="form" onSubmit={this.stopSubmit}>
            <div className="form__form-group-field">
              <Label className="sr-only" htmlFor="tableSearch">
                Search
              </Label>
              <Input
                className="seatch-input"
                id="tableSearch"
                name="tableSearch"
                placeholder={localization.searchTooltip}
                type="search"
                value={searchText}
                onChange={event => onSearchChanged(event.target.value)}
              />
              <Button
                aria-label="Search"
                type="button"
                className="input-group-append"
                color="secondary"
                icon={<FontAwesomeIcon icon={faSearch} />}
              />
            </div>
          </form>
        </Col>
      );
    }
    return null;
  }

  renderDefaultActions() {
    const { actions } = this.props;
    return (
      <div>
        <this.props.components.ToolbarActions
          actions={actions && actions.filter(a => a.isFreeAction)}
        />
      </div>
    );
  }

  renderSelectedActions() {
    const { actions, selectedRows } = this.props;
    return (
      <React.Fragment>
        <this.props.components.ToolbarActions
          actions={actions.filter(a => !a.isFreeAction)}
          data={selectedRows}
        />
      </React.Fragment>
    );
  }

  renderActions() {
    const { selectedRows } = this.props;
    return (
      <div>
        {selectedRows && selectedRows.length > 0
          ? this.renderSelectedActions()
          : this.renderDefaultActions()}
      </div>
    );
  }

  render() {
    const localization = {
      ...MTableToolbar.defaultProps.localization,
      ...this.props.localization,
    };

    const {
      children,
      storyStatusFilters,
      adStatusFilters,
      showAdStatusFilters,
      showStoryStatusFilters,
      selectedRows,
      title,
      tableName,
      getWeekList,
    } = this.props;
    const selectedRowsCount =
      selectedRows && selectedRows.length > 0
        ? localization.nRowsSelected.replace('{0}', selectedRows.length)
        : title;

    return (
      <>
        <Row className="table-toolbar align-items-center">
          {this.renderSearch()}
          {showStoryStatusFilters && tableName === 'story' && (
            <Col xs="auto" className="labeled-toggle-container">
              <select
                className="form-control"
                name="storyStatusFilterSelect"
                id="storyStatusFilterSelect"
                onChange={e => this.storyPagingSummary(e.target.value)}
              >
                <option value="" selected={storyStatusFilters === ''}>
                  All Statuses
                </option>
                <option value="draft" selected={storyStatusFilters === 'draft'}>
                  Draft
                </option>
                <option
                  value="scheduled"
                  selected={storyStatusFilters === 'scheduled'}
                >
                  Scheduled
                </option>
                <option
                  value="published"
                  selected={storyStatusFilters === 'published'}
                >
                  Live
                </option>
                <option value="past" selected={storyStatusFilters === 'past'}>
                  Past
                </option>
              </select>
            </Col>
          )}
          {showAdStatusFilters && tableName === 'interstitialAd' && (
            <Col xs="auto" className="labeled-toggle-container">
              <select
                className="form-control"
                name="adStatusFilterSelect"
                id="adStatusFilterSelect"
                onChange={e => this.adPagingSummary(e.target.value)}
              >
                <option value="" selected={adStatusFilters === ''}>
                  All Statuses
                </option>
                <option value="draft" selected={adStatusFilters === 'draft'}>
                  Draft
                </option>
                <option
                  value="scheduled"
                  selected={adStatusFilters === 'scheduled'}
                >
                  Scheduled
                </option>
                <option
                  value="published"
                  selected={adStatusFilters === 'published'}
                >
                  Live
                </option>
                <option value="past" selected={adStatusFilters === 'past'}>
                  Past
                </option>
              </select>
            </Col>
          )}
          {this.state.result && (
            <Col xs="auto">
              <p>{this.state.result}</p>
            </Col>
          )}
          <Col xs={12} className="col-lg">
            <Row className="align-items-center justify-content-end">
              {children}
              {selectedRowsCount && (
                <Col xs="auto">
                  <h5>{selectedRowsCount}</h5>
                </Col>
              )}
              <Col xs="auto">{this.renderActions()}</Col>
            </Row>
          </Col>
        </Row>
        {true && (
          <Row className="table-toolbar align-items-center">
            <Col xs={12}>
              <FilteredTabs
                border="bottom"
                size="sm"
                className="form-actions fieldset"
                alignment="justify-content-between"
                initialTab="Received"
                content={[...Array(10).keys()].map(weekNumber => ({
                  tabName: `Week ${weekNumber + 1}`,
                  onChange: () => getWeekList(weekNumber + 1),
                }))}
              />
            </Col>
          </Row>
        )}
      </>
    );
  }
}

MTableToolbar.defaultProps = {
  actions: [],
  children: '',
  localization: {
    addRemoveColumns: 'Add or remove columns',
    nRowsSelected: '{0} row(s) selected',
    showColumnsTitle: 'Show Columns',
    showColumnsAriaLabel: 'Show Columns',
    exportTitle: 'Export',
    exportAriaLabel: 'Export',
    exportName: 'Export as CSV',
    searchTooltip: 'Search',
    pagingSummary: 'Showing {0} to {1} of {2}.',
  },
  search: true,
  showStoryStatusFilters: false,
  showAdStatusFilters: false,
  updateStoryStatusFilters: () => {},
  updateAdStatusFilters: () => {},
  storyStatusFilters: '',
  adStatusFilters: '',
  searchText: '',
  selectedRows: [],
  title: '',
  tableName: '',
  components: {
    ToolbarActions,
  },
};

MTableToolbar.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      filter: PropTypes.func,
    })
  ),
  children: PropTypes.node,
  localization: PropTypes.shape({}),
  onSearchChanged: PropTypes.func.isRequired,
  search: PropTypes.bool,
  searchText: PropTypes.string,
  showStoryStatusFilters: PropTypes.bool,
  showAdStatusFilters: PropTypes.bool,
  storyStatusFilters: PropTypes.string,
  adStatusFilters: PropTypes.string,
  updateStoryStatusFilters: PropTypes.func,
  updateAdStatusFilters: PropTypes.func,
  selectedRows: PropTypes.arrayOf(PropTypes.shape({})),
  title: PropTypes.string,
  tableName: PropTypes.string,
  state: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({})),
    pageSize: PropTypes.number,
    page: PropTypes.number,
  }).isRequired,
  components: PropTypes.shape({}),
};

export default MTableToolbar;
/* eslint-enable no-unused-expressions */
/* eslint-enable react/destructuring-assignment */
